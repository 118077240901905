@use "./palette" as *;

//Animation
$animation-type1: ease;
$animation-type2: ease-in;
$animation-type3: ease-out;

//circle
$circle-radius: 50%;
$icon-sizes: (12px, 14px, 16px, 18px, 20px, 22px, 24px, 26px, 28px);

$border-radius0:0px;
$border-radius1:2px;
$border-radius2:4px;
$border-radius3:6px;
$border-radius4:8px;
$border-radius5:10px;
$border-radius6:12px;
$border-radius7:14px;
$border-radius8:16px;
$border-radius9:98px;
$border-radius-rounded:50%;


// Utilities
%flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-full-height{
  display: flex;
  flex-direction: column;  
}
%flex-full-height-auto{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 1px;
}
%screen-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $backDrop1;
  z-index: 8;
}

$boxShadow1: 4px 8px 20px 0px rgba(54, 59, 64, 0.25);


