@use "../../../src/assets/scss/abstracts/index" as *;

.skill-score-wrapper {
    display: flex;
    position: relative;
    height: 6px;

    .skill-nodes-holder {
        display: flex;
        align-self: center;
        // gap: 2px;
        position: relative;
        top: 0;
        left: 0;
        height: 6px;

        &.filled {
            z-index: 2;
            position: absolute;
        }
    }
}

.skill-nodes {
    display: inline-block;
    width: 28px;
    height: 6px;
    background-color: $bgColor5;
    border-radius: $border-radius6;
    font-size: 0;

    &:not(:first-child) {
        margin-left: 2px;
    }


    @media (max-width:1280px) and (max-width:992px) {
        width: 21px;
    }

    &.filled {
        background-color: $bgColor6;
    }
}