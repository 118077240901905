@use "../../../../../assets/scss/abstracts/_palette.scss" as *;
@use "../../../../../assets/scss/abstracts/" as *;

.kvfysmfp{
    flex:1;
}
.node-text-container{
    height: 100%;
    flex-direction: column;
    @extend %flex-all-center;
}
.bubble-wrapper{
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    user-select: none;
    flex-grow:1;    
   overflow: hidden;
   touch-action: none;
}
.bubble-holder{
    flex-grow: 1;
    display: flex;
    justify-content: center; 
    align-items: center;
    transform-origin: 50% 50%;
}
.graph-wrapper{
    width:100%;
    /* min-height: 400px; */
    display: flex;
    flex-direction: column;
    overflow: visible;
    flex-shrink: 0;
}
.circle{
    fill:$adminTileGray;
    stroke:$white;
    stroke-width: 30;
    cursor: pointer;

    &.c-1 {
        fill: $adminTileGray;
    }
    &.c-2{
        fill:$adminTileBlue;
    }
    &.c-3{
        fill:$adminTilePink;
    }
}

.chart-wrapper{
    flex-grow: 1;
    cursor: move;
    position: relative;
}

.bubbleview-tooltip {
    max-width: 150px;
    width: 12%;
    min-width: 120px;
    position: fixed;
    transform: translateX(4%) translateY(-60%);
    transition: opacity 0.4s ease-in-out;
    visibility: hidden;
    z-index: 10;
    opacity: 0;

    &.open {
        visibility: visible;
        pointer-events: none;
        opacity: 1;
    }
    &.tooltip-left{
        transform: translateX(-4%) translateY(-55%) ;
       .bubbleview-tooltip-wrapper::before{
          left:131px;
          @media only screen and (max-width: 1200px) {
            left:120px; 
          }
          @media only screen and (max-width: 1050px) {
            left:104px; 
          }
          
          @media only screen and (max-width: 500px) {
            left:100px; 
           }
       }
     }
    
    
}


.bubbleview-tooltip-wrapper {
    background-color: $black;
    padding-bottom: 4px;
    border-radius: $border-radius4;
    font-weight: $font-weight-medium;
    font-size: $text-sm;

    &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        background-color: $black;
        transform: rotate(45deg) translateY(-50%);
        left: -13px;
        top: 55%;
        border-radius: $border-radius2;
    }
}


.bubbleview-tooltip-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderColor12;
    padding: 14px 12px 8px;

    .skill-item-name {
        color: $white;
        word-break: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 41px);
        line-height: 15px;
    }

    .skill-item-count {
        font-size: $text-md;
        line-height: 17px;
        color: $admintooltippink;
    }
}

.bubbleview-tooltip-bottom {
    padding: 8px 12px;
    @extend %flex-full-height;
    gap: 8px;
}

.skill-num-wrapper {
    display: flex;
    justify-content: space-between;

    .skill-level {
        font-weight: $font-weight-regular;
        line-height: 15px;
        color: $borderColor5;
    }

    .skill-count {
        line-height: 15px;
        color: $white;

    }
}






.react-transform-wrapper {
    border: 1px solid $black;
    width: 100%;
    height: 100%;
}
.react-transform-wrapper .react-transform-component{
    width: 100%;
    height: 100%;
}
.node-text{
    alignment-baseline:middle;
    fill: $black;
    text-anchor: middle;
    pointer-events: none;
    font-weight: $font-weight-medium;
}
.node-skill{
    display: flex;
    align-items: flex-end;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
}
.node-skill,
.count-wrapper{
    flex-grow:1;
    // height: 50%;
}

.count-text{
    alignment-baseline:middle;
    fill: $black;
    text-anchor: middle;
    pointer-events: none;
    font-weight: $font-weight-medium;
}
.count-value{
    font-weight: $font-weight-medium;
}
.count-suffix{
    font-weight: $font-weight-regular;
}
