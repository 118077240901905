@use "../../../assets/scss/abstracts/index" as *;
.label-header{
display: flex;
justify-content: space-between;
}
.accordion-radio:checked~.accordion-content{
    max-height:400px;
    background-color: $white;
  }
  .svg-icon.accordion-arrow-icon{
    width:8px;
    height:8px;
    fill:none;
    transform: rotate(360deg);
    transition: transform 0.3s;
}
  .accordion-radio:checked ~.accordion-title .label-header{
    background-color: $white;
    color:$black;
    .accordion-arrow-icon{
        transform: rotate(-180deg);
        transition: transform 0.3s;
    }
    .badge-icon{
        display: none;
    }
  }
  .accordion-radio{
    opacity: 0;
    pointer-events: none;
    width:0;
    height:0;
    position:absolute;
    left:-2000px;
    top: -2000px;
  }
  .label-header{
    background: transparent;
    padding: 15px 16px 15px 16px;
    color:$black;
    align-items: center;
    cursor: pointer;
    .label-title{
        font-size: 1.1rem;
        font-weight: $font-weight-medium;
        padding-right: 8px;

    }
  }
  .accordion-content{
    max-height:0px;
    overflow:hidden;
    transition:max-height .3s;

  }
  .accordion-section{
    border-top: 1px solid $toggleBg;
    &:first-child{
      border-top: 0px;
    }
  }
 