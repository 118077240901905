@use "../../../../assets/scss/abstracts/" as *;
.admin-wrapper{
  .layout-header {
  border-bottom: 1px solid $borderColor1;
  background: $adminBgPrimary;
  min-height: 180px;
  .header-left-portion {
    .logo-container{
      .logo-icon{
        color: $white;
      }
    }
  }
  .header-right-portion {
    .profile-layout {
      background: $adminBgPrimary;     

      &.active {
        background-color: $adminBgPrimaryLight;
        .profile-header-wrapper {
          background-color: transparent;
          .profile-detail{
            color: $white;
          }
          .profile-img-holder {
            border: 2px solid $bgColor10;
          }
        }
      }
      .dropdown-wrapper {
        background: $white;
        .logout-wrapper {
          .layout-name {
            color: $black;
            &:hover {
              background: $bgColor12;
            }
          }
        }
      }
      .dropdown-icon {
        color:$white;
      }
      .logout-icon {
        color: $adminIconColor;
      }
      .profile-detail {
        color: $white;
      }
    }

  }
}

}
