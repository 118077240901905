@use "../../../../../assets//scss/abstracts/" as *;

.skill-checkbox-search-wrapper {
  &.back-drop {
    @media (max-width: 768px) {
      &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        background: $backDrop1;
        height: 100%;
      }
    }

  }

  .skill-btn-text-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .icon-btn-text {
        border: 0;
        padding-left: 0;
        color: $adminskillBtn;
        padding-top: 24px;

        .plus-icon {
          color: $adminskillBtn;
        }
      }
    }

    .skill-header {
      .skill-main-header {
        font-weight: $font-weight-medium;
        font-size: 14px;
        line-height: 17px;
        color: $headingColor;
      }

      .skill-sub-header {
        font-weight: $font-weight-regular;
        font-size: 11px;
        line-height: 13px;
        color: $primaryColor;
      }
    }

    .secondary {
      gap: 0;

      &.icon-btn-text {
        border: 1px solid $bgColor10;
        border-radius: 6px;
        padding: 8px 16px;

        @media only screen and (max-width: 768px) {
          border: 0;
          color: $adminskillBtn;
          margin-top: 24px;
          padding: 0;
          background: none;
          width: auto;
          font-size: $text-md;
          border: none;

          &:hover {
            background: none;
          }
        }

        .plus-icon {
          color: $adminIconColor;
          margin-right: 8px;

          @media only screen and (max-width: 768px) {
            color: $adminskillBtn;
          }
        }
      }
    }
  }

  .skill-btn-search {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    width: auto;
    padding: 16px 24px 18px 24px;
    margin: 0px -24px 13px -24px;
    background: rgba($bgColor13, 0.82);
    position: relative;

    @media only screen and (max-width: 768px) {
      // display: none;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $white;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
      margin: 0;
      z-index: 3;
      border-radius: 30px 30px 0 0;
      flex-direction: column;
      display: flex;
      gap: 24px;
      padding: 0;
    }

    &:before {
      content: "";
      width: 31px;
      height: 12px;
      position: absolute;
      z-index: 1;
      transform: rotate(0deg);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      top: -8px;
      right: 92px;
      background: $admintooltip;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .input-wrapper {
      width: 100%;
      margin-bottom: 0;

      @media only screen and (max-width: 768px) {
        padding: 24px 24px 0px 24px;
      }

      .input-field {
        height: 36px;

        @media (max-width: 768px) {
          margin-top: 23px;
        }
      }
    }

    .skill-check-btn {
      display: flex;
      margin-top: 0px;
      flex-wrap: nowrap;
      margin-left: 8px;
      column-gap: 4px;
      top: 22px;
      position: relative;
      @media only screen and (max-width: 768px) {
        display: none;
      }

      .icon-with-border {
        padding: 9px;
        width: 36px;
        min-height: 36px;

        .tick-icon {
          fill: none;
          width: 16px;
          height: 16px;
        }

        .close-icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    .add-category-close {
      display: none;

      @media (max-width: 768px) {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }

    .footer-btns {
      display: none;

      @media only screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .skill-checkbox-layout {
    max-height: 200px;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      max-height: max-content;
    }
  }
}

.add-skill {
  .skill-checkbox-search-wrapper {
    .skill-btn-text-layout {
      margin-bottom: 24px;
    }
  }
}

.review-skill {
  .input-wrapper {
    margin-bottom: 0px;
  }

  .skill-btn-text-layout {
    margin-bottom: 24px;
  }

  .input-span-text {
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: $font-weight-regular;
    font-size: $text-sm;
    line-height: 15px;
    color: $primaryColor;

    .span-subset {
      font-weight: $font-weight-medium;
      color: $black;
      margin-left: 4px;
    }
  }
}

.export-modal {
  margin-bottom: 16px;

  .export-content {
    font-weight: $font-weight-regular;
    font-size: $text-xs-plus;
    line-height: 16px;
    color: $primaryColor;

  }
}