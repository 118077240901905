@use "../../../../../../assets/scss/abstracts/" as *;

.uplod-skill-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba($darkBrown, 0.14);
  min-height: 400px;
  overflow: auto;
  @media screen and (max-width: 758px) {
    min-height: 600px;
  }
}

.upload-catgeory-mainpage {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px;
  min-height: 400px;
  gap: 24px;

  .folder2-icon {
    width: 76px;
    height: 76px;
  }

  .add-category-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    color: $headingColor;
   & > span:first-child {
      color: $headingColor;
      font-size: $text-lg;
      font-weight: $font-weight-bold;
    }
    & > span:last-child {
      margin-top: 10px;
    }
  }
  .add-category-buttons-holder {
    display: flex;
    gap: 8px;
    .addmanual-btn {
      padding: 8px 42px;
    }
  }
}
.upload-excelsheet-wrapper {
  padding: 40px 24px;

  .upload-details-wrapper {
    display: flex;
    justify-content: space-between;
    color: $black;
    font-size: $text-lg;
    .upload-header {
      display: flex;
      align-items: center;
      gap: 8px;
      .excel-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .progress-bar-holder {
    margin-top: 12px;
    .progress-bar-border {
      border-radius: 100px;
      background: $fancyBg7;
      .progress-bar {
        border-radius: 100px;
        height: 12px;
        background-color: $fancyBg5;
      }
    }
  }
  .upload-button-holder {
    margin-top: 24px;
    .canceluplod-btn {
      padding: 8px 41px;
      color: $adminPrimaryBlack;
      background: $white;
      transition: none;
    }
  }
}
