@use "../../../src/assets/scss/abstracts/index" as *;

.skill-score-stack {
    @extend %flex-full-height;
    // gap: 10px;
}

.skill-score-card {
    display: flex;
    gap: 10px;
    width: 100%;
    position: relative;

    &:not(:first-child) {
        margin-top: 10px;
    }

    .skill-score-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2px;
        border: 1px solid $borderColor7;
        width: 100%;
        padding: 10px 8px 10px 36px;
        border-radius: $border-radius2;
        margin: 0;
        position: relative;
        cursor: pointer;

        @media (max-width:768px) {
            padding: 16px 16px 16px 40px;
        }

        .skill-score-label {
            flex-grow: 1;
            text-transform: uppercase;
            color: $primaryColor;
            font-size: $text-xs;

            @media (max-width:768px) {
                font-size: $text-sm;
                color: lighten($primaryColor, 40);
            }
        }
    }

    .skill-score-radio {
        position: absolute;
        display: block;
        left: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        appearance: none;

        @media (max-width:768px) {
            left: 15px;
        }

        &::before {
            position: absolute;
            width: 16px;
            height: 16px;
            display: block;
            background: $white;
            top: 0;
            left: 0;
            content: '';
            border: 1px solid $borderColor8;
            border-radius: $border-radius-rounded;
            background-color: $white;
        }

        &::after {
            position: absolute;
            width: 10px;
            height: 10px;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            content: '';
            border-radius: $border-radius-rounded;
        }

        &:checked {
            &::before {
                position: absolute;
                width: 16px;
                height: 16px;
                display: block;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                content: '';
                border-color: $bgColor6;
            }

            &::after {
                background-color: $bgColor6;
            }

            &+.skill-score-label {
                @media (max-width:768px) {
                    color: $primaryColor;
                }

                &::before {
                    border-color: $bgColor6;
                    box-shadow: 0px 0px 4px 0px $bgColor6;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                    content: '';
                    top: 0;
                    left: 0;
                    position: absolute;
                    border-radius: $border-radius2;

                    .skill-score-label {
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }
    }
}