@use '../abstracts/index' as *;

label{
    color: $black;
    font-size: $text-sm;
    line-height: 1.2;
    margin-bottom: 8px;
    font-weight: $font-weight-medium;
    sup {
            color: $errorColor;
            display: inline-block;
            margin-left: 3px;
        }
}