@use '@experiondesign/front-end-framework/common' with (
$prefix:"",
);
@use "fonts";
@use "abstracts/index" as *;
@use "common" as *;
@use "components/svg-icon";
@use "components/buttons" as *;
@use "components/form-controls" as *;
@use "components/table" as *;

html {
  height: 100%;
  background: $white;
}
body {
  @extend %flex-full-height;
  min-height: 100%;
  font-family: $body-font;
  font-weight: $font-weight-regular;
  height: 100%;
  font-size: 1.4rem;
  color: $black;
}
:root{
  --base-padding:4px;
  --base-margin:4px;
}

#root {
  @extend %flex-full-height;
  height: 100%;
}
