@use "../../../../assets/scss/abstracts/" as *;

.welcome-section {
  padding: 42px 32px 0;

  @media (max-width:991px) {
    padding: 0;
  }

  .welcome-user-profile {
    color: $black;

    // @media (max-width:991px) {
    //   display: none;
    // }
    .user-name {
      font-weight: $font-weight-semi-bold;
      font-size: $text-xl-plus;

      @media (max-width:991px) {
        font-size: $text-xl;
      }
    }

    .user-content {
      margin-top: 5px;
      max-width: 620px;
      font-weight: $font-weight-regular;
      line-height: 21px;
      font-size: $text-md;

      .mobile-text {
        display: none;
        font-size: $text-sm;

        @media (max-width:991px) {
          display: block;
        }
      }

      .main-text {
        display: block;

        @media (max-width:991px) {
          display: none;
        }
      }
    }
  }

  .filter-panel-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .icon-only.filter-panel-icon {
      .btn-text {
        margin-left: 3px;
        font-weight: $font-weight-medium;
        font-size: $text-sm;
        line-height: 15px;
        color: $black;
      }
    }
  }

}