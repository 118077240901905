@use "../../../../assets/scss/abstracts/" as *;
.admin-content{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  position: relative;
  margin-top: -130px;
  @media (max-width:991px) {
    margin-top: -104px;
    padding: 4px;
    background: $adminBgPrimary;
  }
}