@use "../../../../../../assets//scss/abstracts/" as *;
.reject-reason-skill {
  .input-wrapper {
    .input-field {
        height: 82px;
      &::placeholder {
        top: -20px;
        position: relative;
      }
    }
  }
  .reject-btn-wrapper {
    display: flex;
    column-gap: 8px;
    margin-bottom: 24px;
    @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        row-gap: 12px;
      }
    .app-btn.only-btn-text {
      padding: 7.5px 12px;
      background: $white;
      border: 1px solid $skyBlue;
      border-radius: 6px;
      font-weight: $font-weight-medium;
      font-size: $text-sm;
      line-height: 12px;
      color: $adminBgPrimaryLighter;
    }
  }
}
