@use "../../../../../assets/scss/abstracts/" as *;
.faq-accordion-wrapper {
  .accordion-section.section-header {
    &.faq-header {
      border-top: 0;
      .accordion-title {
        .label-header {
          padding-left: 16px;
          padding-bottom: 20px;
          padding-top: 20px;
          .label-title {
            font-size: $text-md;
            font-weight: $font-weight-semi-bold;
          }
        }
      }
      .accordion-content {
        line-height: 20px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid rgba($black, 0.1);
        transition: max-height 0.1s;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .accordion-radio {
        top: auto;
        left: auto;

        &:checked {
          & ~ .accordion-title .label-header {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            margin-top: 5px;
            .accordion-arrow-icon {
              .icon-plus {
                display: none;
              }
              .zoom-out {
                display: block;
              }
            }
          }

          & ~ .accordion-content {
            padding-bottom: 12px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom: 1px solid $white;
          }
        }
      }

      .accordion-arrow-icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1.5px solid $primaryColor;
        padding: 5px;
        .zoom-out {
          display: none;
          width: 8px;
        }
        .icon-plus {
          display: block;
          width: 8px;
        }
      }
    }
  }
  &.domain-accordion-wrapper {
    border-top: 1px solid $toggleBg;
  }
}
