// Font family
$font-primary: 'Inter', sans-serif;;
$font-secondary: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
$body-font: $font-primary, $font-secondary;

// Font weights
$font-weight-thin:100;
$font-weight-extra-light:200;
$font-weight-light:300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-extra-black: 900;

// Font sizes
$text-xs: 1rem; // 10px
$text-xs-plus: ($text-xs + 0.1); // 11px
$text-sm: ($text-xs + 0.2); // 12px
$text-sm-plus: ($text-xs + 0.3); // 13px
$text-md: ($text-xs + 0.4); // 14px
$text-md-plus: ($text-xs + 0.5); // 15px
$text-lg: ($text-xs + 0.6); // 16px
$text-lg-plus: ($text-xs + 0.7); // 17px
$text-xl: ($text-xs + 0.8); // 18px
$text-xl-plus: ($text-xs + 0.9); // 19px
$text-xxl: ($text-xs + 1); // 20px
$text-xxl-small: ($text-md + 0.8); // 22px 
$text-xxl-small-plus: ($text-xxl + 0.4); // 24px 
$text-xxl-plus: ($text-xs + 2); // 30px
$text-xxl-large: ($text-xs + 2.6); // 36px

