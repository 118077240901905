@use "../../../../assets/scss/abstracts/" as *;

.admin-wrapper {
  .user-notification-dropdown {

    .notification-layout-set {
      .notification-admin-content {
        margin-bottom: 8px;
      }
    }

    .notification-icon {
      color: $white;
    }

    &.dropdown-open {
      .notification-nav .menu-arrow {
        display: block;

        &::before,
        &::after {
          content: "";
          width: 22px;
          height: 7px;
          transform: rotate(0deg);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }

        &::before,
        &::after {
          left: calc(50% - 11px);
          top: calc(100% + 11px);
          background-color: $white;
        }
      }

      .nav-dropdown-btn {
        background: $adminNavyBlue;
      }

      .notification-nav.active {
        &::before {
          display: none;
        }
      }
    }

    .notification-nav {
      position: relative;

      .menu-arrow {
        display: none;
      }
    }

    .sub-menu-header-menu-wrapper {
      right: 0px;
      left: auto;

      @media only screen and (max-width: 991px) {
        border-radius: 0;
      }

      .notification-header {
        @media only screen and (max-width: 991px) {
          margin-bottom: 8px;
          background-color: $white;
          padding: 19px 21px 19px 19px;
        }

        .page-header {
          @media only screen and (max-width: 991px) {
            margin-left: 14px;
          }
        }

        .status-count {
          color: $black;
          background: $bgColor13;

        }
      }

      .notification-main-content {
        max-height: 50vh;

        // height: calc(100vh - 170px);
        @media only screen and (max-width: 991px) {
          overflow: auto;
          height: 100%;
          background-color: $white;
          max-height: 100%;
        }

        .notification-item {
          display: flex;

          // column-gap: 12px;
          .notification-layout-set {
            margin-left: 12px;
          }
        }

        .bold-name {
          font-weight: $font-weight-semi-bold;
          color: $brownShade;
         
        }

        .profile-img-holder {
          height: 42px;
          width: 42px;
          border: 2px solid $white;
          border-radius: 50%;

          .user-img {
            height: 38px;
            width: 38px;
            border-radius: 50%;
          }
        }

        .notification-date {
          font-weight: $font-weight-regular;
          font-size: $text-sm;
          line-height: 15px;
          color: $lightDark;
          display: inline-block;
          position: relative;
          background: $white;

          &.date-check {
            margin-left: 16px;

            &::before {
              content: "";
              position: absolute;
              left: -10px;
              top: 7px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: $lightDark;
            }
          }

          @media only screen and (max-width: 991px) {
            display: inline-block;
          }
        }
      }
    }

    .notification-nav.active {
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $lightRed;
        border: 1px solid transparent;
      }
    }
  }

}