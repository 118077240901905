@use "../../../../assets/scss/abstracts/" as *;

.selectBox-Wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    color: var(--black-b-100, #6B6B6B);
    font-size: $text-sm;
    line-height: 1.2;
    margin-bottom: 8px;
    font-weight: $font-weight-medium;

    sup {
      color: $errorColor;
      display: inline-block;
      margin-left: 3px;
    }
  }

  .react-select-box {
    &:focus {
      background-color: $onFocus;
    }
  }

  .react-select-box___control--is-focused {
    border: 1px solid $onFocus;
    box-shadow: 0px 0px 5px rgba(59, 129, 223, 0.7);

    &:hover {
      border-color: $black;
    }
  }

  .react-select-box___value-container {
    padding: 5.5px 6px;
    min-height: 50px;
  }

  .react-select-box___placeholder {
    font-size: $text-lg;
    font-weight: $font-weight-medium;
    padding-left: 4px;
  }

  .react-select-box___input {
    font-size: $text-lg;
    font-weight: $font-weight-medium;
  }

  .react-select-box___multi-value {
    padding: 7px 10px 7px 16px;
    background: $lightGrey;
    border-radius: $border-radius3;
    margin-right: 8px;
  }

  .react-select-box___multi-value__label {
    font-size: $text-lg;
    line-height: 1;
    color: $white;
    font-weight: $font-weight-semi-bold;
    padding: 2px;
    margin-right: 5px;
  }

  .___multi-value__remove {
    background-color: transparent;
    padding: 0;
    color: $white;

    &:hover {
      background: lighten($lightGrey, 10);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .react-select-box__single-value {
    padding-left: 4px;
    font-size: $text-md;
    font-weight: $font-weight-regular;
    line-height: normal;
  }

  .react-select-box___indicator-separator {
    display: none;
  }

  .react-select-box___indicator {
    color: $black;
  }

  .inputField {
    width: 100%;
    border: 1px solid $primaryColor;
    border-radius: $border-radius4;
    height: 50px;
    padding: 0px 12px;
    outline: none;
    font-size: $text-lg;
    color: $primaryColor;
    font-weight: $font-weight-medium;

    &.bold {
      font-weight: $font-weight-bold;
    }

    &:focus {
      border: 1px solid $onFocus;
      box-shadow: 0px 0px 5px rgba($onFocus, 0.7);
    }
  }

  .validationMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    color: $errorColor;
    font-weight: $font-weight-medium;
    font-size: $text-sm;
  }

  .react-select-box__indicator-separator {
    display: none;
  }

  .react-select-box__control {
    background: $white;
    border: 1px solid $borderColor5;
    border-radius: $border-radius3;
    min-height: 40px;

    &:hover {
      border: 1px solid $black;
    }
  }

  .react-select-box__control--is-disabled {
    background-color: $lightBlue;
  }

  .react-multi-select__indicators {
    display: none;
  }

  .react-select-box__indicator {
    color: $greyish;
    width: 30px;
  }

  .react-multi-select__multi-value {
    flex-direction: row-reverse;
    align-items: center;
    background: $lightWhiteShade;
    border: 1px solid $skyBlue;
    border-radius: $border-radius6;
    cursor: pointer;
    padding: 6px 6px;
    margin: 6px 5px 6px 0;

    .react-multi-select__multi-value__remove {
      display: none;
    }
  }

  .countSelect {
    // flex-direction: row-reverse;
    align-items: center;
    background: $lightWhiteShade;
    border: 1px solid $skyBlue;
    border-radius: $border-radius6;
    cursor: pointer;
    padding: 6px 12px;
    margin: 6px 0px 6px 2px;
    font-size: $text-sm;
  }

  .react-multi-select__multi-value__label {
    font-weight: $font-weight-regular;
    font-size: $text-sm;
    line-height: 14px;
    color: $black;
    padding: 0;
    margin-left: 4px;
    max-width: 120px;
  }

  .react-multi-select__multi-value__remove {
    padding: 0;
    color: $primaryColor;

    &:hover {
      background-color: transparent;
      color: $primaryColor;
    }
  }

  .react-multi-select__control {
    &:hover {
      border: 1px solid $bgColor11;
    }
  }

  .react-multi-select__value-container {
    padding: 0 10px;
    min-height: 40px;
  }

  .react-multi-select__menu {
    overflow: hidden;
    margin-top: 4px;
  }

  .react-multi-select__menu-list {
    max-height: 200px;
    overflow-y: auto;
    padding: 6px;
  }

  .react-multi-select__option {
    padding:0  0 8px 0;

    &.react-multi-select__option--is-focused,
    &.react-multi-select__option--is-selected {
      background-color: $bgColor22;
    }
    .checkbox-wrapper{
      padding-bottom: 0;
    }
  }

  .react-multi-select__menu {
    background-color: $bgColor22;
  }

  .dropdown-arrow {
    position: absolute;
    color: gray;
    right: 11px;
    top: 36px;
    pointer-events: none;
  }
  
 .checkbox-wrapper input[type=checkbox] {
background-color: $white;
  }
}

.category-list{
background-color:  #E7F0FB;
padding: 23px  24px 20px ;
margin-bottom: 23px;
}
.user-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0  24px;
  

  .skill-level-section {
    .skill-level-heading {
      color: $bgColor21;
      font-size: $text-sm;
      font-weight: $font-weight-medium;
      padding-bottom: 13px;
    }
    .checkbox-wrapper{
      &:not(:last-child){
      padding-bottom: 8px;
      }
    }
  }

  .skill-type-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .skill-type-heading {
      color: $bgColor21;
      font-size: $text-sm;
      font-weight: $font-weight-medium;
    }
    .link-btn{
      min-height: auto;
      color: $urlColor;
    }

    .skill-radiobutton-holder {
      display: flex;
      border-radius: 4px;
      border: 1px solid $borderColor13;
      width: 100%;
      // border: 1px solid #D6DEE7;

      .radiobutton {
  
        padding: 8px;
        min-width: 124px;
         @media only screen and (max-width: 768px) {
      flex: 1 1;
         }

        &.blue-shade {
          background: $bgColor4;
        }
        &.left-side{
         min-width: 104px;
         border-right: 1px solid $borderColor13;
        }
        &.right-side{
          min-width: 124px;
        }

        .radio-btn-wrapper {
          padding-bottom: 0;
          & input[type=radio] {
            margin-right: 4px;
        }
      }

      }
    }
  }
}
.duration-holder{
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.duration-heading {
  color: var(--black-b-100, #6B6B6B);
    font-size: 1.2rem;
    font-weight: $font-weight-medium;
}
.range-slider-holder.domain-slider-duration{
  .range-slider-data{
    gap:0;
  }
  .range-values-data{
    padding: 0;
  .rangeValues-heading{
    color: var(--black-b-100, #6B6B6B);
   font-size: 12px;
    font-weight: $font-weight-medium;
  }
   .rangeValues{
    color: $adminPrimaryBlack;
    font-weight: $font-weight-regular;
    font-size: 16px;
   } 
  

}

  .range-values-data{
    padding: 0;
  }
  .range {
    border: 0;
    padding: 18px 10px 0;
    color:  $adminBgPrimaryLight;
    z-index: 0;

    .slider-range-input  .slider-input::-webkit-slider-thumb {
  border:8px solid $adminBgPrimaryLight;
  background-color: $white;
}
.slider-range-input .slider-input::-moz-range-thumb {
  border: 8px solid $adminBgPrimaryLight;
  background-color: $white;
}
.slider-middle {
    background-color: rgba(167, 186, 218, 0.91);
    border-top: 2px solid $adminBgPrimaryLight;
    border-bottom: 2px solid $adminBgPrimaryLight;

}
.range-slider {
    border-top: 3px dashed $adminBgPrimaryLight;
}
  }
}