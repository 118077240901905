@use "../../../../assets/scss/abstracts/" as *;
.admin-skills-wrapper{
  display: flex;
  flex-direction: column;
  background-color: $bgColor1;
  flex:1 1 1px;
  overflow-y: auto;
  background-color: $white;
  border-top: 1px solid $bgColor13;
  border-radius: 0 0 $border-radius4 $border-radius4; 
  @media (max-width:991px){
    border-top: none;
  } 
}