@use "../../../../../assets/scss/abstracts/" as *;
.faq-main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  height: 100%;
  @media (max-width: 991px) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
  .faq-img-wrapper {
    .page-img{
      @media (max-width: 991px) {
        width: 150px;
        height: 150px;
      }
    }

  }
  .faq-wrapper {
    margin-top: 50px;
    @media (max-width: 991px) {
     margin-top: 0px;
    }

    @media (max-width: 480px) {
      text-align: center;
    }
    
    .faq-heading {
      font-weight: $font-weight-semi-bold;
      font-size: 42px;
      line-height: 51px;
      color: $black;
      max-width: 370px;
      @media (max-width: 991px) {
        font-size: 27px;
      }

      @media (max-width: 480px) {
        max-width: none;
        font-size: 23px;
      }
    }
    .faq-content {
      margin-top: 29px;
      font-weight: $font-weight-regular;
      font-size: $text-md;
      color: $black;
      line-height: 25px;
      @media (max-width: 991px) {
        margin-top: 0px;
       }
      .faq-mail {
        font-weight: $font-weight-medium;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 3px;
      }
    }
  }
}
