@use "../../../../assets/scss/abstracts/" as *;
.skill-search-wrapper {
  padding: 10px 0 16px 0;
  position: relative;
  .search-filter-wrapper{
    display: flex;
    .icon-only.filter-panel-icon{
      display: none;
       @media (max-width: 991px) {
        display: block;
        margin-left: 15px;
        .filter-icon{
          width: 25px;
          height: 25px;
        }     
      }  
    }
  }
  .search-field{
    position: relative;
    display: flex;
    flex-grow: 1;
    .input-wrapper .input-field{
        padding-left: 32px;
        @media (max-width: 991px) {
            padding-left: 40px;
          }
      }
      .input-wrapper{
        margin-bottom: 0;
        flex-grow: 1;
      }
      .search-icon {
        color: $adminLightGray;
        position: absolute;
        left: 12px;
        top: 15px;
        @media (max-width: 991px) {
            top: 15px;
            left: 18px;
          }
      }
  }

  @media (max-width: 991px) {
    padding: 19px 24px 12px 24px;
    background-color: $white;
  }
  .skill-mbl-header{
    display: none;

    .back-btn-header-wapper{
        display: flex;
        .back-arrow{
            .back-arrow-icon{
                fill:none;
            }
        }
        .page-header{
            font-weight: $font-weight-medium;
            font-size: $text-xl;
            line-height: 30px;
            color: $black;
            @media (max-width: 991px) {
                margin-left: 21px;
               }
        }
    }
    .btn-icon-wrapper{
        display: flex;
        color: $borderColor4;
        font-weight: $font-weight-medium;
        font-size: $text-md;
        .btn-text{
            margin-left: 5px;
        }
    }
    @media (max-width: 991px) {
       display: block;
       display: flex;
       justify-content: space-between;
       padding-bottom: 19px;
      }
  }    
}
