@use "../../../../assets/scss/abstracts/" as *;
.help-wrapper{
  position: relative;
  margin-right: 24px;
  top: 17px;
  @media (max-width:768px) {
    top: 16px;
    margin-right: 4px;
  }
  &.active{
    .help-btn {
      color: $black;
    }
    .help-dropdown {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .help-btn{
    color: $primaryColor;
    width: 24px;
    height: 24px;
    padding: 0;
    outline: none;
    .help-icon{
      width: 20px;
      height: 20px;
      fill: none;
    }
  }
  .help-dropdown{
    position: absolute;
    top: 34px;
    left: -28px;
    border-radius: $border-radius6;
    background-color: $white;
    box-shadow: 0px 10px 30px 3px rgba(5, 16, 55, 0.15);
    z-index: 3;
    opacity: 0;
    pointer-events: none;

    @media (min-width: 768px) {
      width: 227px;
    }

    @media (max-width: 767px) {
      width: max-content;
    }

    &::before {
      content: "";
      width: 23px;
      height: 8px;
      position: absolute;
      box-shadow: 0px 0px 16px rgb(0 0 0 / 23%);
      z-index: 1;
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
      left: 29px;
      top: -8px;
      background-color: $white;
    }
    ul{
      li{
        
        position: relative;
          &::before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: calc(100% - 32px);
            height: 0.5px;
            background-color: (rgba($black, 5%));
          }
        &:first-child{
          &::before{
            display: none;
          }
          a{
            border-radius: $border-radius6 $border-radius6 0 0;
          }
        }  
                &:last-child {                
        
                  a {
                    border-radius:0 0 $border-radius6 $border-radius6;
                  }
                }
        a{
          display: block;
          padding: 14px 16px;
          text-decoration: none;
          color: $black;
          font-size: $text-sm-plus;
          font-weight:$font-weight-medium;
          transition: background-color ease 0.2s;
          &:hover{
            background-color: $bgColor3;
          }
        }
      }
    }
  }
}