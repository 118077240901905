@use "../../../../../../assets/scss/abstracts/" as *;

.user-modal-wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .sub-section {
        padding: 14px 14px 14px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &:not(:last-child) {
            border-bottom: 1px solid $borderColor14;
        }

        .sub-section-header {
            color: $black;
            font-size:$text-sm;
            font-weight:$font-weight-semi-bold;
        }

        .sub-section-skillholder,.sub-section-domainholder {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .box-holder {
                display: flex;
                border-radius: 4px;
                border: 1px solid $borderColor15;

                .left-section {
                    padding: 4px 8px;
                    border-color: $borderColor13;
                    border-radius: 4px 0 0 4px;
                    color: $fancyBg8;
                  
                    font-size:$text-sm;
              
                    font-weight:$font-weight-medium;
                   
                }

                .right-section {
                    padding: 4px 8px;
                    color: $secondaryColor;
                    border-color: $borderColor15;
                    border-radius: 0 4px 4px 0;
                    background-color: $bgColor19;
                    font-size: $text-xs;
                   font-weight:$font-weight-regular;
                    text-transform: uppercase;
                    border-left: 1px solid $borderColor15;
            

                }

            }
        }


    }
 .primary-skill-section {
        padding-top: 0;
    }
}




.certification-list-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
     border-radius: 10px;
   position: relative;
    gap: 8px;
    flex-shrink: 0;

    @media (max-width: 991px) {
        width: 100%;
    }

    .certification-holder {
        padding: 4px 0;
    }

    .certification-title {
        color: $fancyBg8;
        font-size:$text-sm;
    font-weight: $font-weight-medium;
     color: $black;
        word-break: break-all;
        margin-bottom: 4px;
    }

    .expiry-date-tag {
        color: $fancyBg8;
       font-size:$text-xs;
         font-weight:$font-weight-regular;
     

    }




}

.domain-list-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
     border-radius: 10px;
   position: relative;
    gap: 8px;
    flex-shrink: 0;

    @media (max-width: 991px) {
        width: 100%;
    }

    .domain-holder {
        padding: 4px 0;
    }

    .domain-tag {
       font-size:$text-xs;
         font-weight:$font-weight-regular;
        padding-top: 6px;
        color: #000000;
        font-size: 12px;
        padding-left: 3px;

    }




}

.domain-box-holder {
    display: flex;
    border-radius: 4px;
    border: 1px solid $borderColor15;
    @media (max-width: 991px) {
        // flex-direction: column;
    }


    .domain-name {
        padding: 4px 8px;
        border-color: $borderColor13;
        border-radius: 4px 0 0 4px;
        color: $fancyBg8;
      
        font-size:$text-sm;
  
        font-weight:$font-weight-medium;
        @media (max-width: 991px) {
            // border-bottom: 1px solid $borderColor13;
        }
    
       
    }
    .domain-wrapper{
        display: flex;
        @media (max-width: 991px) {
            // flex-direction: column;
        }
    
    }

    .domain-level {
        padding: 4px 8px;
        color: $secondaryColor;
        border-color:  $borderColor15;
        background-color: $bgColor19;
        font-size: $text-xs;
       font-weight:$font-weight-regular;
        text-transform: uppercase;
        border-left: 1px solid $borderColor15;
        // border-right: 1px solid $borderColor15;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 5px 5px 0;

    }
    .sub-domain-name {
        padding: 4px 8px;
        border-color: $borderColor13;
        // color: $fancyBg8;
      
        // font-size:$text-sm;
  
        // font-weight:$font-weight-medium;

        color: $fancyBg8;
        font-size:$text-xs;
          font-weight:$font-weight-regular;
 

        


    }

}
