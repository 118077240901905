@use "../../../../assets/scss/abstracts/" as *;

.admin-filter-drop-down {
  display: flex;
  flex-direction: column;
  width: 834px;
  height: 439px;
  box-shadow: $boxShadow1;
  border-radius: $border-radius4;
  position: absolute;
  top: 40px;
  left: -180px;
  z-index: 15;
  background-color: $white;

  @media (max-width:1200px) {
    width: calc(100% - 80px);
    position: fixed;
    top: 172px;
    margin: auto;
    left: 0;
    right: 0;
  }

  @media (max-width:991px) {
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }

  .filter-header {
    display: none;
    padding: 19px 24px;
    background-color: $adminBgPrimary;

    @media (max-width:991px) {
      display: flex;
      align-items: center;
    }

    .back-btn {
      @extend %flex-all-center;
      border: none;
      padding: 0;
      gap: 20px;
      color: $white;
      font-weight: $font-weight-medium;
      font-size: $text-xl;
      padding: 0;
      background: none;

      svg {
        fill: transparent;
        width: 8px;
        height: 18px;
      }
    }
  }

  .filter-nav {
    padding: 24px 24px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width:991px) {
      padding: 8px 0 0;
      background-color: $bgColor9;
    }
  }

  .filter-menu {
    position: relative;
    padding: 0 30px;
    margin: 0 -60px;

    @media (max-width:991px) {
      padding: 0;
      margin: 0;
      height: 100%;

      &::before {
        background-color: $white;
        width: calc(100% - 140px);
        height: 100%;
        top: 0;
        left: 140px;
        display: block;
        content: '';
        position: absolute;
        pointer-events: none;
      }
    }

    &::after {
      display: block;
      clear: both;
      content: '';
      width: 100%;
    }

    .filter-menu-item {
      display: block;
      width: 30%;
      float: left;
      padding: 0 30px;
      position: relative;

      @media (max-width:991px) {
        padding: 0;
        width: 100%;
        pointer-events: none;
        position: static;
      }

      &.two-col {
        width: 70%;

        @media (max-width:991px) {
          width: 100%;
        }

        .filter-menu-list {
          max-height: 320px;
          overflow-y: auto;

          @media (max-width:991px) {
            display: none;
            max-height: 100%;
          }

          .skill-checkbox-layout {
            display: grid;
            grid-template-rows: repeat(max(calc(var(--itemCount) / 2), 9), 1fr);
            grid-auto-flow: column;
            grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
            align-items: start;
          }

          &.active {
            display: block;
            pointer-events: auto;

            .skill-checkbox-layout {
              @media (max-width:991px) {
                display: block;
                pointer-events: auto;
              }
            }
          }
        }
      }

      .filter-tab-btn {
        border: none;
        padding: 0;
        width: 100%;
        border-bottom: 1px solid rgba($black, 0.1);
        line-height: 1.2;
        padding-bottom: 12px;
        border-radius: 0;
        display: block;
        text-align: left;
        font-weight: $font-weight-semi-bold;
        text-transform: uppercase;
        pointer-events: none;
        background: none;

        @media (max-width:991px) {
          width: 140px;
          pointer-events: auto;
          padding: 16px 10px 16px 24px;
          border-bottom: none;
          font-size: $text-sm;

          &.active {
            background-color: $white;
          }
        }
      }
    }

    .filter-menu-list {
      padding: 16px 0;

      @media (max-width:991px) {
        display: none;
        position: absolute;
        width: calc(100% - 140px);
        top: 0;
        left: 140px;
        background-color: $white;
        padding: 20px 16px;
      }

      &.active {
        @media (max-width:991px) {
          display: block;
          pointer-events: auto;
        }
      }

      .checkbox-wrapper {
        padding-bottom: 12px;

        @media (max-width:991px) {
          padding-bottom: 22px;
        }

        .checkbox-label {
          @media (min-width:992px) {
            width: 200px;
            overflow-wrap: break-word;
            color: #000000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

        }

      }
    }
  }

  .filter-footer {
    border-top: 1px solid rgba($black, 0.1);
    padding: 0 24px;

    .btn-group.align-right {
      gap: 0;

      .app-btn:not(:last-child) {
        margin-right: 8px;
      }
    }

    @media (max-width:991px) {
      border-top: none;
      box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 2;
    }
  }
}
.filter-width-custom{
  @media (min-width: 991px) {
    width: 344px;
    height: auto;
    .filter-menu .filter-menu-item.two-col{
      width: 100%;
    }
  }
}