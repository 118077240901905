@use "../../../assets/scss/abstracts/index" as *;

.page-loader {
    min-height: 200px;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 200px;
    max-width: 100%;
}

.load-dot {
    animation: page-loading 1.3s infinite linear;
}

.load-dot1 {
    transform-origin: 16% 50%;
}

.load-dot2 {
    animation-delay: 0.2s;
    transform-origin: 50% 50%;
}

.load-dot3 {
    animation-delay: 0.5s;
    transform-origin: 83% 50%;
}

@keyframes page-loading {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.6);
    }

    100% {
        transform: scale(1);
    }

}