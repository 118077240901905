@use "../../../../../assets/scss/abstracts/palette" as *;
.input-wrapper{
    --base-padding:4;
}
.add-certificate{
    padding-top: 12px;
    .no-expiry{
        position: relative;
        margin-top: -11px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 23px;
        @media only screen and (max-width: 768px){
            justify-content: flex-start;
            margin-bottom: 19px;
        }
        .app-checkbox{
            padding-left: 8px;
            padding-top: 2px;
        }
    }
    .layout.fr2.full-width{
        @media only screen and (max-width: 768px){
            display: flex;
            .box{
               width: 100%;
               &.pl-5{
                padding-left: 0;
               }
            }
        }
    }
    .input-tag-holder{
        margin-bottom: 37px;
    }
}

.react-multi-select__placeholder {
    padding-bottom: 1px; /* fix for letter cutoff (y,p,g,q letters)*/
}