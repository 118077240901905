//Theme for project

$white:                             #ffffff;
$black:                             #000000;

// Colors
$headingColor:                      #1E202C;
$primaryColor:                      #4B4D55;
$secondaryColor:                    #9453D5;
$tertiaryColor:                     #6B6A66;
$colorDim:                          #898B94;
$errorColor:                        #D6514F;
$tabActive:                         #258AC3;
$tabActiveLighter:                  #307CE1;
$disabled:                          #757575;
$optionBtn:                         #83909F;     
$urlColor:                          #1a73e8;
$urlColorHover:                     #0f4ea1;
$primaryBtnDisabledBg:                #bfd9fb;
$primaryBtnDisabled:              #97aab3;

// Border colors
$borderColor1:                      rgb($black, 0.1);
$borderColor2:                      #6F2BB3;
$borderColor3:                      #FFCE00;
$borderColor4:                      #0D4898;
$borderColor5:                      #ABBACC;
$borderColor6:                      #BEAECF;
$borderColor7:                      #DDDDDD;
$borderColor8:                      #969696;
$borderColor9:                      #A2A1A3;
$borderColor10:                      #EFEFEF;
$borderColor11:                      #E5E5E5;
$borderColor12:                     #FFFFFF2B;
$borderColor13:                     #D6DEE7;
$borderColor14:                     #0000001A;
$borderColor15:                     #D8BDF3;
$borderColor16:                    #D5DADD;
$borderColor17:                    #E6E6E6;
$borderColor18:                   #C3C3C3;
$borderColor19:                    #0000000d;
$onFocus:                           #3B81DF;

// Background Colors
$transparent:                       transparent;
$bgColor1:                          #EAEAEA;                      
$bgColor2:                          #E6F3FD;                      
$bgColor3:                          #F5F9FE;                      
$bgColor4:                          #E7F0FB;                      
$bgColor5:                          #F5EEFC;                      
$bgColor6:                          #9453D5;    
$bgColor7:                          #FAE9B6; 
$bgColor8:                          rgb(#515457,0.22);
$bgColor9:                          #EFF3F4;
$bgColor10:                         #B5C2DA;
$bgColor11:                         #252C34;
$bgColor12:                        #d9eafa;
$bgColor13:                        #E7EEF4;
$bgColor14:                        #F4F4F4;
$bgColor15:                        #EDEDED;
$bgColor16:                        #F5F9FD;
$bgColor17:                        #E2E2E2;
$bgColor18:                        #F2F7FD;
$bgColor19:                        #FAF5FF;
$bgColor20:                         #212121;
$bgColor21:                         #6B6B6B;
$bgColor22:                         #F7F9FC;
$bgColor23:                         #9bc4f4;
$bgColor24:                         #E8EFF2;
$bgColor25:                         #EBEBEB;
 
// Fancy Bg Colors
$fancyBg1:                          #EC9C3F;                
$fancyBg2:                          #627180;   
$fancyBg3:                          #DFAB25;   
$fancyBg4:                          #898E96; 
$fancyBg5:                           #5FBF91;
$fancyBg6:                             #f4ebfc;
$fancyBg7:                           #e9ecef;
$fancyBg8:                           #311F42;
$fancyBg9:                         #303030;
$fancyBg10:                         #429F69;

// Controls theme
$dragIcon:                          #D0C7D9;

// Backdrops
$backDrop1:                         rgba(#0D0F11,0.35);

// Box Shadows
$modalBoxShadow1:                   0px 18px 34px 0px rgba(5, 16, 55, 0.15);
$cardBoxShadow:                     0px 0px 24px 0px #628CC214;



// Backdrops
$backDrop1:                         rgba(#0D0F11,0.35);

// Box Shadows
$modalBoxShadow1:                   0px 18px 34px 0px rgba(5, 16, 55, 0.15);

//additional Colors 
$lightRed:#EE6464;
$lightRed1:#FF3333;
$lightDark:#8390a8;
$brownShade:#282b32;
$darkBrown:#47546c;
$lightBlue:#f3f5f7;
$blackBoxShadow:#051037;
$lightGrey:#6B6A66;
$lightWhiteShade:#F8FAFB;
$skyBlue:#9EC1EF;
$toggleBg:#DFE1E6;
$greyish:#61636F;

// ADMIN THEMES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
$adminBgPrimary:                    #0B2555;
$adminBgPrimaryLight:               #204180;
$adminBgPrimaryLighter:             #307CE1;
$adminBgPrimaryLighter-2:             #76A7E9;
$adminPrimaryBlack:#232323;
$adminNavyBlue:#233B66;
$adminLightGray:#828282;
$adminIconBg:#f3f7fb;
$adminIconBorder:#CFD6E1;
$adminSearchBorder:#E3E7EF;
$adminIconColor:#9fa6b0;
$adminTileGray:#F2F2F2;
$adminTileGray200:#E4E4E4;
$adminTileBlue:#E7F0FF;
$adminTileBlue200:#CDE0FF;
$adminTilePink:#E2DCFE;
$adminTilePink200:#D4CBFE;
$adminPrimaryGray:#808080;
$admingrayDot:#D9D9D9;
$adminSkyPrimary:#4390eb;
$adminPinkPrimary:#dc468e;
$adminLightPurple:#d8d1fd;
$adminBtnLightBtn:#B5C6D3;
$adminSkyBlue:#D5E4FC;
$admintooltip:#ebf1f6;
$adminfooter:#F3F3F4;
$adminLoginBg:#edf1f4;
$adminBoxShadow:#e9eef1;
$adminskillBtn:#0D4898;
$admintooltippink:#C18FF4;
$admingray:#7E858E;
$admintilehover:#DBEFFF;
$adminPurpleLight:#F6F4FF;

// Status Colors
$statusGreen100:#ECFDF5;
$statusGreen300:#DCF8EA;
$statusGreen800:#1EAE7D;

$statusOrange100:#FEFBE8;
$statusOrange300:#F2F3B3;
$statusOrange800:#F39A2A;

$statusPink100:#FDF2F1;
$statusPink300:#F5DCE0;
$statusPink800:#E05066;

$statusBlue100: #19c0fd25;
$statusBlue300: #cef2ff;
$statusBlue800: #00c2fd;
