@use "../../../../../assets/scss/abstracts/" as *;


.selection-box{
position: relative;

.dropdown-arrow{
  position: absolute;
color: $black;
 right: 8px;
  top: 8px;
  pointer-events:none;
}
.selectBoxWrapper{
max-height: 39px!important;
.react-select-box__control{
  max-height: 39px!important;
}
.react-select-box-page__control{
  min-height: 29px;
  .react-select-box-page__value-container {
    padding: 2px 10px;

  }
}
.react-select-box-page__indicator-separator  {
  display: none;
}
.react-select-box-page__indicator{
  padding: 8px;
  svg{
    display: none;
  }
}
}
}
.radio-selection-box{
  position: relative;
  width: 100%;

    padding: 8px 18px ;
  

.css-b62m3t-container{
  border: 1px solid $lightDark;
  position: static;
  width: max-content;
  border-radius: 6px;
}

  .react-radio-select__indicator-separator{
    display: none;
  }
  .react-radio-select__menu{
    position: absolute;
    // top:29%;
    width: 94%;
  }
  .react-radio-select__control {
    border-radius: 6px;
    border: 1px solid $lightDark;
  }
  .react-radio-select__menu-list {
    padding: 12px 8px;
  }
  .react-radio-select__multi-value__remove {
    padding: 0;
    color: $primaryColor;

    &:hover {
      background-color: transparent;
      color: $primaryColor;
    }
  }
  .react-radio-select__option{
    padding: 0;
    .checkbox-wrapper{
      padding-bottom: 0;
      .radio-btn-wrapper{
        padding-bottom: 0;
      }
    }
   
  }
  .react-radio-select__option {
    padding:0  0 8px 0;

    &.react-radio-select__option--is-focused,
    &.react-radio-select__option--is-selected {
      background-color: $bgColor22;
    }
  }
  .react-radio-select__menu {
    background-color: $bgColor22;
  }

  .react-radio-select__placeholder {
    color: $bgColor20;
 font-size: $text-sm;
 font-weight: $font-weight-regular;
  }

  .react-radio-select__value-container {
    padding-right: 0;
  }

  .react-radio-select__indicator {
    color: $greyish;
   
    padding-left: 0;
  }
}