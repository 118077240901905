@import "../../../assets/scss/abstracts/";

.relative{
        position: relative;
    }
.input-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
    position: relative;
    @media only screen and (max-width: 768px){
        margin-bottom: 19px;
    }
    
    label{
        color: $black;
        font-size: $text-sm;
        line-height: 1.2;
        margin-bottom: 8px;
        font-weight: $font-weight-medium;
        sup {
                color: $errorColor;
                display: inline-block;
                margin-left: 3px;
            }
    }
    .input-field{
        width: 100%;
        border: 1px solid $borderColor5;
        border-radius: $border-radius4;
        height: 40px;
        line-height: 1.21;
        padding: 12px;
        outline: none;
        font-size: $text-md;
        color: $black;
        font-weight: $font-weight-regular;     
        &:hover{
            border: 1px solid $bgColor11;
        }
        &:focus{
            border: 1px solid $onFocus;
            box-shadow: 0px 0px 5px rgba($onFocus, 0.7);
        }
        &:disabled{
            background: $lightBlue;
            border: 1px solid $borderColor5;
        }
        &::-webkit-input-placeholder { /* Edge */
        color: $colorDim;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $primaryColor;
        }

        &::placeholder {
        color: $colorDim;
        }
        
    }
    .validation-message{
        margin-top: 5px;
        color:$errorColor;
        font-weight: $font-weight-medium;
        font-size: $text-sm;
        .validationText-icon{
            display: flex;
            gap:4px;
            align-items: center;
            color: $lightRed1;
            font-size:$text-xs;
            font-weight:$font-weight-regular;
            .svg-icon{
                width: 16px;
                height: 16px;
            }
        }
    }
}