@use '../abstracts/index' as *;
.icon-only{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    cursor: pointer;
    background: none;
}
.app-btn {
    // font-family: $body-font;
    font-weight:$font-weight-medium;
    text-decoration: none;
    border: 1px solid $black;
    background-color: transparent;
    background-clip: padding-box; /* prevents background overflowing transparent border color */
    position: relative;
    line-height: 1.2;
    font-size: $text-sm;
    padding: 8.5px 27.5px;
    border-radius: $border-radius2;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $black;
    transition: all ease 0.2s;
    gap: 8px;
    @media (max-width: 991px) {
      border-radius: 12px;
      padding: 15.5px 27.5px;
      font-size: $text-md;
      font-weight:$font-weight-semi-bold;
      }
    &.lg{
        min-height: 48px;
        padding: 15.5px;
        line-height: 1.2;
        font-size: $text-md;
        font-weight: $font-weight-semi-bold;
      }
    &:hover{
        border-color: $bgColor11;
        color: $bgColor11;
    }
    &:disabled,
    &.disabled {
        border-color: $disabled;
        color: $disabled;
    }    
    &.full{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    &.min-width{
        min-width: 96px;
    }
    // button -- primary
    &.primary {
        background-color: $black;
        color: $white;
        &:hover{
            background-color: $bgColor11;
            border-color: $bgColor11;
            color: $white;
        }

        &:disabled,
        &.disabled {
            border-color: $disabled;
            background-color: $disabled;
        }
        &.min-width{
            min-width: 140px;
        }
    }
    &.link-btn{
        @extend %flex-all-center;
        border: none;
        padding: 0;
        gap: 8px;
        background: none;
        &:hover{
            background: none;
        }
        &.underline{
            border-bottom: 1px solid $primaryColor;
            padding: 0;
            border-radius: 0;
            min-height: auto;
            font-weight: $font-weight-regular;
            &:hover{
                color: $tabActive;
                border-color: $tabActive;
            }
        }
    }
    &.add-btn{
        @extend %flex-all-center;
        border: none;
        padding: 0;
        gap: 8px;
        font-size: $text-md;
        font-weight: $font-weight-medium;
        color: $borderColor4;
        .icon-plus{
            width: 12px;
            height: 12px;
            stroke-width:1.5;
        }
        .btn-text{
            line-height: 1;

        }
    }
        &.icon-only-sm {
            height: 24px;
            width: 24px;
            padding: 0;
            min-height: auto;
            border-color: $adminIconColor;
            border-radius: 4px;
            border: 1px solid rgba(71, 84, 108, 0.14);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $darkBrown;
            background: $white;
            &.ghost{
                border: none;
                background: none;
            }
    
            &:hover {
                background: $bgColor18;
            }
            .svg-icon{
                width: 16px;
                height: 16px;
                fill: none;
            }

        }
        &.add-new-icon-btn{
            padding: 6px 12px;
        }

}
.btn-select-all{
    color: $tabActiveLighter;
    border: none;
    background: none;
    font-size: $text-md;
    line-height: 1.3;
    display: inline-flex;
    &:hover{
        color: $urlColorHover;
    }
}