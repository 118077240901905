@use "../../../../../../assets/scss/abstracts/palette" as *;
@use "../../../../../../assets/scss/abstracts/" as *;
.user-listing-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  &.certificate-app {
    .user-skill-item-wrapper {
      .user-skill-row {
        .user-skill-level {
          left: 0px;
        }
      }
    }
  }
  .skill-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .skill-heading {
    display: flex;
    font-weight: $font-weight-medium;
    font-size: $text-sm;
    line-height: 15px;
    color: $darkBrown;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba($black, 0.05);
    @media only screen and (max-width: 768px) {
      display: none;
    }
    .admin-skill-header.name {
      flex: 40%;
    }
    .admin-skill-designation.designation {
      flex: 30%;
      display: flex;
      justify-content: flex-start;
    }
    .admin-skill-bu.level {
      flex: 22%;
    }
  
    .admin-skill-level.duration{
      flex: 18%;
    }
  }
  .user-skill-item-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    .user-skill-row {
      display: flex;
      align-items: center;
      padding: 13px 0px 13px 0px;
      font-weight: $font-weight-medium;
      font-size: $text-sm;
      line-height: 15px;
      color: $black;
      border-bottom: 1px solid rgba($black, 0.05);
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        padding: 12px 0px 16px 0px;
      }
      .user-skill-detail {
        flex: 40%;
        display: flex;
        align-items: center;
        column-gap: 8px;
        @media only screen and (max-width: 768px) {
          display: inline-block;
          padding-left: 50px;
          position: relative;
          flex: auto;
          flex-grow: 0;
          margin-left: 16px;
        }
        .profile-img-holder {
          height: 42px;
          width: 42px;
          border: 2px solid $white;
          border-radius: 50%;
          @media only screen and (max-width: 768px) {
            position: absolute;
            left: 0;
            top: 0px;
          }
          .user-img {
            height: 38px;
            width: 38px;
            border-radius: 50%;
          }
        }
        .user-skill-name-id {
          .user-skill-name {
            font-weight: $font-weight-medium;
            font-size: $text-md;
            line-height: 17px;
            color: $black;
            word-break: break-all;
            @media only screen and (max-width: 768px) {
              position: absolute;
              white-space: nowrap;
              padding-left: 0;
            }
          }
          .user-skill-id {
            font-weight: $font-weight-regular;
            font-size: $text-xs-plus;
            line-height: 13px;
            color: $darkBrown;
            margin-top: 4px;
            @media only screen and (max-width: 768px) {
              position: relative;
              top: 20px;
              margin-top: 6px;
            }
          }
          @media only screen and (max-width: 768px) {
            height: 35px;
            position: relative;
          }
        }
      }
      .user-skill-designation {
        flex: 30%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        word-break: break-all;
        @media only screen and (max-width: 768px) {
          flex: auto;
          flex-grow: 0;
          margin-top: 23px;
          margin-left: 16px;
          &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $admingrayDot;
            border: 1px solid $admingrayDot;
          }
        }
      }
      .user-skill-bu {
        flex: 20%;
        word-break: break-all;
        @media only screen and (max-width: 768px) {
          flex: auto;
          margin-top: 23px;
          margin-left: 16px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $admingrayDot;
            border: 1px solid $admingrayDot;
          }
        }
      }
      .user-skill-level {
        flex: 20%;
        position: relative;
        left: 15px;
        word-break: break-all;
        @media only screen and (max-width: 768px) {
          display: flex;
          width: 100%;
          margin-left: 65px;
          margin-top: 10px;
          flex: auto;
        }
        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: -15px;
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
        &.primary {
          color: $adminSkyPrimary;
          &:before {
            background: $adminSkyPrimary;
          }
        }
        &.secondary {
          color: $adminPinkPrimary;
          &:before {
            background: $adminPinkPrimary;
          }
        }
        &.additional {
          color: $fancyBg4;
          &:before {
            background: $fancyBg4;
          }
        }
      }
    }
  }
  .download-footer-btn {
    display: none;
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 19px 24px 29px 24px;
      background: $white;
      box-shadow: 0px -1px 11px rgba($black, 0.1);
      z-index: 4;
      margin: 0 -20px;
      width: calc(100% + 36px);
      .app-btn {
        background: $adminBgPrimaryLighter;
        border-radius: 6px;
        border: 1px solid $adminBgPrimaryLighter;
        color: $white;
        padding: 8px 16px 8px 16px;
        .down-icon {
          width: 16px;
          height: 16px;
          fill: none;
        }
      }
    }
    @media only screen and (max-width: 375px) {
      margin: 0 -10px;
      width: calc(100% + 15px);
    }
  }
}
