@use "../../../../assets/scss/abstracts/" as *;
.admin-action-header{
 
}
.admin-tabs{
  background-color: $adminBgPrimaryLight;
  border-radius: $border-radius4 $border-radius4 0 0;
  justify-content: flex-start;
  overflow: hidden;
  @media (max-width:991px) {
    border-radius: 30px 30px 0 0;
    background-color: $adminBgPrimary;
  }
  nav{
    display: inline-flex;
    @media (max-width:991px) {
      display: flex;
    }
    @media (max-width:480px) {
      overflow: hidden;
      overflow-x: auto;
    }
    ul{
      border-bottom: none;
      height: 45px;
      @media (max-width:991px) {
        width: 100%;
      }
      @media (max-width:480px) {
        width: auto;
      }
      li{
        .app-btn{
          padding: 12px 28px 14px 28px;
          color: $white;
          border-radius: $border-radius5 $border-radius5 0 0;
          border: none;
          position: relative;
          transition: none;
          font-weight: $font-weight-regular;
          background: none;
          @media (max-width:991px) {
            border-radius: 30px 30px 0 0;
            padding: 14px 28px 14px 28px;
          }
          &:hover{
            background: none;
          }
          &.active{
            background-color: $white;
            color: $adminBgPrimary;
            &::before,&::after{
              position: absolute;
              display: block;
              content: '';
              width: 12px;
              height: 18px;              
              bottom: 0px;
              background-color: $adminBgPrimaryLight;              
              
              pointer-events: none;
              @media (max-width: 991px) {
                  width: 24px;
                  box-shadow: -2px 16px 0px 0 #ffffff;
                  height: 35px;
                  background-color: $adminBgPrimary;
              }
              
            }
             &::before{
              left: -12px;
              right: inherit;
              border-radius: 0 0 $border-radius6 0;
              
              box-shadow: 1px 8px 0px 0 $white;
              @media (max-width: 991px) {
                border-radius: 0 0 30px 0;
                left: -24px;
                box-shadow: 4px 14px 0px 0 $white;
              }
             }
             &::after{
              right: -12px;
              height: 20px;
              border-radius: 0 0 0 $border-radius6;
              box-shadow: -1px 10px 0px 0 $white;
              @media (max-width: 991px) {
                border-radius: 0 0 0 30px;
                right: -24px;
                box-shadow: -3px 12px 0px 0 $white;
                height: 33px;
              }
             }
          }
          

        }
      }
    }
  }
}