
@use "../../../../assets/scss/abstracts/" as *;

.certification-wrapper {
  display: none;

  @media (max-width: 991px) {
    border-top: none;
    display: none;
    overflow-y: visible;
    flex-grow: 1;
    height: 100%;
    padding: 24px 0 0;

    &.show-this {
      display: flex;
      flex-direction: column;
      max-height: 100%;
    }
  }

  .certificate-header {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      display: none;
    }

    .page-header {
      font-weight: $font-weight-regular;
      font-size: $text-md;
      line-height: 17px;
      color: $black;
    }

    .status-count {
      padding: 2px 6.5px;
      font-size: $text-md;
      min-width: 25px;
      height: auto;
      text-align: center;
      line-height: 15px;
      color: $white;
      font-weight: $font-weight-regular;
      position: relative;
      margin-left: 6px;
      background: $fancyBg2;
      border-radius: 54px;
    }

    .app-btn.add-new-icon-btn {
      margin-left: 12px;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  

  .certificate-footer-btn {
    display: none;

    @media (max-width: 991px) {
      display: flex;
      width: 100%;
      padding: 19px 24px 29px 24px;
      background: $white;
      box-shadow: 0px -1px 11px rgba($black, 0.1);
      z-index: 4;
      margin: 20px 0 0;

      .app-btn {
        width: 100%;
        border-radius: $border-radius6;
      }
    }
  }
}

.certification-item-layout {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  position: relative;

  @media (max-width: 991px) {
    padding: 0 24px 0 24px;
  }

  .certification-list-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: inherit;
    background: $bgColor3;
    border-radius: 10px;
    padding: 12px 40px 12px 12px;
    position: relative;
    margin-bottom: 8px;
    flex-shrink: 0;

    @media (max-width: 991px) {
      width: 100%;
    }

    .certification-left-section {
      flex-grow: 1;
      cursor: pointer;
    }

    .certification-title {
      font-weight: $font-weight-semi-bold;
      font-size: $text-lg;
      line-height: 19px;
      color: $black;
      word-break: break-all;
    }

    .certification-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // column-gap: 24px;
      font-weight: $font-weight-medium;
      font-size: $text-sm;
      line-height: 15px;
      padding-top: 9px;
      word-break: break-all;

      @media (max-width: 991px) {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 10px;
      }

      .issused-by-name {
        color: $primaryColor;
        margin-right: 24px;

        .issue-name {
          color: $black;
        }
        &.domain-name{
          margin-right: 0;
        }
      }
      .issused-by-date {
        font-size: $text-xs-plus;
        color: $primaryColor;
        font-weight: $font-weight-medium;

        .issue-date {
          color: $black;
        }
      }

      .expiry-date-tag {
        display: flex;
        align-items: center;
        color: $primaryColor;

        .issue-name {
          color: $black;
        }
      }

      .expiry-warning {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        font-size: $text-sm;
        line-height: 15px;
        color: $errorColor;

        .warning-icon {
          margin-right: 3px;
        }

        .expired-name {
          margin-left: 2px;
        }

        .expiry-warning-date {
          margin-left: 2px;
        }
      }
    }

    .option-dropdown-wrapper {
      top: 10px;
      right: 8px;

      @media (max-width: 768px) {
        top: 8px;
        right: 14px;
      }

      .icon-option {
        width: 18px;
        height: 18px;
        padding: 0;
        background-color: transparent;

        .svg-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          color: $optionBtn;
          border-radius: $border-radius3;
          color: $borderColor5;
          height: 18px;
          width: 9px;
        }
      }
    }
  }

  .no-data-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
  }
}
.modal-footer-zindex {
  @media (max-width: 768px) {
    z-index: 1;
  }
}
.faq-accordion-wrapper {
  padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1 1 1px;
    overflow-y: auto;
  .accordion-section {
      border-top: 1px solid $toggleBg;
      &:first-child{
        border-top: 1px solid $toggleBg;
      }
      &:last-child{
        border-top: 0px;
      }
    &.section-header{
      &.faq-header {
        .accordion-title {
        .accordion-arrow-icon {
          &.dropdown-arrow-icon {
            border: none;
            color: $greyish;
            .dropdown-icon {
              fill: none;
            }
          }
        }
        }
      }
    }
    .accordion-content{
      gap: 8px !important;
    }
    +.accordion-section{
      border-top: 0px;
    }
  }
}
.accordion-radio:checked~.accordion-content{
  max-height:400px;
  background-color: $white;
}
