@use "../../../../../assets/scss/abstracts/" as *;
.layout-header {
  padding: 16px 24px;
  padding-top: 8px;
  border-bottom: 1px solid $borderColor1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 78px;
  @media (max-width:991px) {
    border-bottom: 0 none;
  }
  .header-left-portion {
    display: flex;
    align-items: center;
    height: 62px;
    column-gap: 10px;
    .icon-only {
      position: relative;
      top: -5px;
    }
    .logo-container {
      .logo-icon {
        width: 122px;
        height: 35px;
      }
    }
    .hamburger-icon {
      width: 24px;
      height: 24px;
    }
  }
  .header-right-portion {
    display: flex;
    align-items: flex-start;

    .profile-layout {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      background: $white;
      border-radius: 10px;
      margin-right: -12px;
      max-width: 250px;

      @media (max-width:768px) {
        max-width: 180px;
      }

      @media (max-width: 767px) {
        position: relative;
      }

      .profile-header-wrapper {
        padding: 8px 12px 8px 16px;
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &.active {
        box-shadow: 0px 2px 10px rgba(5, 16, 55, 0.2);
        z-index: 3;

        @media (min-width: 768px) {
          overflow: hidden;
        }

        @media (max-width: 767px) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .profile-header-wrapper {
          background: $bgColor3;
          .profile-img-holder {
            border: 2px solid $bgColor10;
          }
          .dropdown-arrow {
            transform: rotate(180deg);
          }
        }
        .dropdown-wrapper {
          display: flex;

          @media (min-width: 768px) {
            width: 100%;
          }

          @media (max-width: 767px) {
            width: max-content;
          }
        }
      }
      .profile-header-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        .dropdown-arrow {
          margin-left: 8px;
        }
      }

      .dropdown-wrapper {
        width: 100%;
        flex: 1;
        display: none;

        @media (max-width: 767px) {
          position: absolute;
          top: 50px; /* height of profile-header-wrapper div */
          right: 0;
          z-index: -1;
          min-width: 148px;
          background-color: $bgColor3;
          border-radius: 10px;
          border-top-right-radius: 0;
          box-shadow: 0px 2px 10px rgba(5, 16, 55, 0.2);
        }

        .logout-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
          .logout-item {
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
          }
          .layout-name {
            justify-content: space-between;
            width: 100%;
            padding: 12px;
            display: flex;
            font-weight: $font-weight-medium;
            font-size: $text-sm;
            line-height: 15px;
            color: $black;
            &:last-child{
              &:hover{
                border-radius: 0 0 $border-radius5 $border-radius5;
              }
            }
            &:hover {
              background: $bgColor12;
            }
          }
        }
      }
      .logout-icon,
      .dropdown-icon {
        fill: none;
      }
      .dropdown-icon {
        color: $primaryColor;
        width: 9px;
        height: 5px;
      }
      .logout-icon {
        width: 16px;
        height: 16px;
        color: $adminIconColor;
      }
      .profile-detail {
        font-size: $text-xs;
        padding-right: 8px;
        color: $primaryColor;
        text-align: right;

        @media (max-width: 767px) {
          display: none;
        }

        .profile-name {
          font-weight: $font-weight-semi-bold;
          line-height: $text-sm;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .profile-designation {
          font-weight: $font-weight-regular;
          line-height: $text-sm;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .profile-img-holder {
        height: 38px;
        width: 38px;
        border: 2px solid $white;
        border-radius: 50%;
        position: relative;
        flex-shrink: 0;
        @media (max-width:768px){
          width: 34px;
          height: 34px;
        }
        .user-img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          object-fit: cover;
         position: absolute;
         font-size: $text-xl;
        }
      }

      .profile-detail-mobile {
        padding: 12px;
        font-size: $text-xs;
        line-height: 15px;

        @media (min-width: 768px) {
          display: none;
        }

        .profile-name {
          font-weight: $font-weight-medium;
          color: $black;
        }

        .profile-designation {
          color: $primaryColor;
        }
      }
    }
  }
}
