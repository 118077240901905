@import "../../../assets/scss/abstracts/";

.creatableWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  position: relative;
  label {
    color: $black;
    font-size: $text-sm;
    line-height: 1.2;
    margin-bottom: 8px;
    font-weight: $font-weight-medium;
    sup{
      color: $errorColor;
      display: inline-block;
      margin-left: 3px;
    }
  }
  .react-select-box{
    &:focus{
      background-color: $onFocus;
    }
  }
  .react-select-box___control--is-focused {
    border: 1px solid $onFocus;
    box-shadow: 0px 0px 5px rgba(59, 129, 223, 0.7);
    &:hover {
      border-color: $black;
    }
  }
  .react-select-box___value-container {
    padding: 5.5px 6px;
    min-height: 50px;
  }
  .react-select-box___placeholder {
    font-size: $text-lg;
    font-weight: $font-weight-medium;
    padding-left: 4px;
  }
  .react-select-box___input {
    font-size: $text-lg;
    font-weight: $font-weight-medium;
  }
  .react-select-box___multi-value {
    padding: 7px 10px 7px 16px;
    background: $lightGrey;
    border-radius: $border-radius3;
    margin-right: 8px;
  }
  .react-select-box___multi-value__label {
    font-size: $text-lg;
    line-height: 1;
    color: $white;
    font-weight: $font-weight-semi-bold;
    padding: 2px;
    margin-right: 5px;
  }
  .___multi-value__remove {
    background-color: transparent;
    padding: 0;
    color: $white;
    &:hover {
      background: lighten($lightGrey, 10);
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .react-select-box__single-value {
    padding-left: 4px;
    font-size: $text-md;
    font-weight: $font-weight-regular;
    line-height: normal;
  }
  .react-select-box___indicator-separator {
    display: none;
  }
  .react-select-box___indicator {
    color: $black;
  }

  .inputField {
    width: 100%;
    border: 1px solid $primaryColor;
    border-radius: $border-radius4;
    height: 50px;
    padding: 0px 12px;
    outline: none;
    font-size: $text-lg;
    color: $primaryColor;
    font-weight: $font-weight-medium;
    &.bold {
      font-weight: $font-weight-bold;
    }
    &:focus {
      border: 1px solid $onFocus;
    box-shadow: 0px 0px 5px rgba($onFocus, 0.7);
    }
  }
  .validationMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    color:$errorColor;
    font-weight: $font-weight-medium;
    font-size: $text-sm;
  }

  .react-select-box__indicator-separator {
    display: none;
  }
  .react-select-box__control {
    background: $white;
    border: 1px solid $borderColor5;
    border-radius: $border-radius3;
    min-height: 40px;
    &:hover{
      border: 1px solid $black;
    }
  }
  .react-select-box__control--is-disabled{
    background-color: $lightBlue;
  }
  .react-multi-select__indicators {
    display: none;
  }
  .react-select-box__indicator {
    color: $greyish;
    width: 30px;
  }
  .react-multi-select__multi-value {
    flex-direction: row-reverse;
    align-items: center;
    background: $lightWhiteShade;
    border: 1px solid $skyBlue;
    border-radius: $border-radius6;
    cursor: pointer;
    padding:6px 12px;
    margin:6 5px 6px 0;
  }
  .react-multi-select__multi-value__label {
    font-weight: $font-weight-regular;
    font-size: $text-sm;
    line-height: 12px;
    color: $black;
    padding: 0;
    margin-left: 12px;
  }
  .react-multi-select__multi-value__remove {
    padding: 0;
    color: $primaryColor;
    &:hover {
      background-color: transparent;
      color: $primaryColor;
    }
  }
  .react-multi-select__control {
    &:hover{
      border: 1px solid $bgColor11;
    }
  }
  .react-multi-select__value-container{
    padding: 0 10px;
  }
  .react-multi-select__menu{
    overflow: hidden;
  }
  .react-multi-select__menu-list{
    max-height: 200px;
    overflow-y: auto;
  }

  .react-multi-select__single-value {
    padding-bottom: 1px; /* fix for letter cutoff (y,p,g,q letters)*/
  }
}