@use "../../../../../assets/scss/abstracts/" as *;

.skilllist-grid-view {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  padding-top: 8px;
  @media (max-width: 991px) {
    padding-top: 0;
  }

  .skilllist-layout {
    flex: 1 1 1px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 12px 16px 16px 16px;

    @media (max-width: 991px) {
      padding-top: 0;
    }
    .layout {
      gap: 16px;
      @media (max-width: 1279px) {
        --responsive-box-count: 3;
      }
      @media (max-width: 991px) {
        --responsive-box-count: 2;
      }
      &.addCategoryWrap {
        margin-bottom: 22px;
        @media (max-width: 991px) {
          margin-bottom: 16px;
        }
      }
    }
    .pagination-wrapper {
      margin-top: auto;
    }
  }
}
.category-item {
  border-radius: $border-radius6;
  background: $white;
  border: 6px solid $bgColor2;
  display: flex;
  flex-direction: column;
  min-height: 172px;
  padding: 0;
  width: 100%;
  &.add {
    border-radius: $border-radius5;
    border: 1px dashed $admintooltippink;
    background-color: $adminPurpleLight;
    padding: 16px 20px;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    max-width: 292px;
    transition: background-color 0.5s ease;
    &:hover{
      background-color: $white;
    }
    .plus-icon {
      width: 16px;
      height: 16px;
    }
  }
  .title {
    color: $black;
    text-align: center;
    font-size: $text-sm;
    font-weight: $font-weight-regular;
    line-height: normal;
  }
}
.category-item-set {
  border: 0;
  padding: 32px 16px 20px 16px;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
  min-height: 137px;
  background: none;
  border-radius: 10px;
  overflow: hidden;  
  
  .category-item-content{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      height: 100%;
      z-index: 1;
  }
  .folder-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    top: 0;
    left: 0;
    z-index: 0;
    // clip-path: polygon(30% 0, 35% 10%, 100% 10%, 100% 100%, 0 100%, 0 0);
    // background: $adminTilePink;
    &::after{
      position: absolute;
      bottom: 0;
      right:0;
      border-radius: 10px;
      content: '';
      display: block;
      height: calc(100% - 12px);
      width: 100%;
      background: $adminTilePink;
    }
    .closed-folder-icon{
      width: 100%;
      height: 100%;
      color: $adminTilePink;
    }
    
  }
  .foldericonWrapper {
    .folder-icon {
      width: 32px;
      height: 26px;
    }
  }
  .skillTitle {
    // margin-top: 12px;
    display: block;
    width: 100%;

    .title {
      color: $black;
      font-size: $text-lg;
      font-weight: $font-weight-semi-bold;
      line-height: normal;
      text-align: left;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
    }
  }
  .count {
    color: $black;
    font-size: $text-sm;
    text-align: left;
    margin-top: 4px;
    .number {
      color: $black;
      font-size: $text-sm;
      font-weight: $font-weight-semi-bold;
      line-height: normal;
    }
  }
  .status {
    color: $admingray;
    font-size: $text-xs;
    font-weight: $font-weight-regular;
    line-height: normal;
    margin-top: auto;
    text-align: left;
    margin-top: auto;
    align-self: flex-end;
  }
}
.modal-wrapper{
   .modal-header {
        h4 {
          font-size: $text-xl;
          font-weight: $font-weight-semi-bold;
        }
      }  
   .close-icon{
        @media only screen and (max-width: 768px){
          // display: none;
        }
}

}

.modal-wrapper .modal-body {
  padding-top: 24px;
}
.input-wrapper {
  margin-bottom: 34px;
}


.th-open{
    display: flex;  
}
.th-close{
    display: none;
}


  .skill-data-botttom{
   padding:15px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    background-color: $white;
    border-top:1px solid rgba($darkBrown, 0.14);
    border-radius:  0 0 $border-radius6 $border-radius6;
    &.edit-mode{
 padding: 20px 24px;
    }
    &.upload-page{
     padding: 26px 24px;
    }
    
  
    .modified-status{
           color: #7E858E;
            font-weight:$font-weight-regular;
             font-size: $text-xs;
           
   }
 
  
    
  
  .modification-status-holder{
    .edit-icon-btn {
        border: 1px solid rgba($darkBrown, 0.14);
        padding: 8px 16px;
         z-index: 2;
        background-color: $white;
        color: $black;

        &:hover {
          background-color: rgba($darkBrown, 0.14);
        }

     

        .edit-icon {
          fill: none;
          width: 16px;
          height: 16px;
          color: $adminIconColor;
        }
      }
      .modification-status{
           color: $black;
          font-size: $text-xs;
          border-radius: 20px;
          padding: 6px 10px;
          background:  $fancyBg6;
      }
}
}
  .skill-data-main{
    overflow: auto;
    flex:1 1;
    min-height: 140px;
    // max-height: 390px;
    &.hasScroll{
      .skill-data-wrapper{
        margin-right: 14px;
        @media (max-height: 768px) {
          margin-right: 14px;
        }
        
      }
    }
  }
  .admin-c-modal .modal-wrapper .modal-header .modal-header-top .modal-header-wrapper .modal-header-content .modal-sub-header {
     color:$black;
  }
  .modal-header-wrapper {
      gap:12px;

      @media only screen and (min-width: 769px) {
        width: 90%;
      }

      .modal-header-icon{
        .closed-folder-icon{
          width: 40px;
          height: 40px;
        }
      }
      .modal-header-content{
   width:100%;
      }
    
    .custom-head-editicon{
      display: flex;
      gap:16px;
      align-items: flex-start;
 
      .edit-icon{
     
          fill: none;
          color: $darkBrown;
          width: 16px;
          height: 16px;
      }
      }
   
    .custom-head-edit-holder{
  display: flex;
  flex-direction: column;
   .custom-head-edit{
        display: flex;
        gap: 8px;
        .input-wrapper{
          display: block;
          margin-bottom: 0;
          width:80%;
          .input-field{
         font-size: $text-xl;
          font-weight: 600;
          }
         }
          .skildata-btn{
                  top:7px;
                  position: relative;
                  .cross-icon{
                      fill: none;
                      width: 14px;
                      color:$darkBrown;
                     
                      @media only screen and (max-width: 768px){
                          display: block;
                      }
                  }
                  .plus-icon{
                              fill: none;
                              width: 16px;
                              height: 16px;
                              color: $adminIconColor;
                          }
              }
      }
        .modal-sub-header{
          margin-left: 12px;
          &.error{
            margin-left: 0;
          }
      
        }
      }
}

    

    
      