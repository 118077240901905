@use '../../../assets/scss/abstracts/' as *;

.btn-loader {
    color: transparent;

    &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 50%;
        margin-left: -5px;
        top: calc(50% + 5px);
        border-radius: 50%;
        animation: loading 1.3s infinite linear;
    }
}

@keyframes loading {
    0% {
        box-shadow:
            -15px -10px 0 -5px $white,
            0 -10px 0 -2.5px $white,
            15px -10px 0 0 $white;
    }

    25% {
        box-shadow:
            -15px -10px 0 -2.5px $white,
            0 -10px 0 0px $white,
            15px -10px 0 -2.5px $white;
    }

    50% {
        box-shadow:
            -15px -10px 0 0px $white,
            0 -10px 0 -2.5px $white,
            15px -10px 0 -5px $white;
    }

    75% {
        box-shadow:
            -15px -10px 0 -2.5px $white,
            0 -10px 0 -5px $white,
            15px -10px 0 -2.5px $white;
    }

    100% {
        box-shadow:
            -15px -10px 0 -5px $white,
            0 -10px 0 -2.5px $white,
            15px -10px 0 0px $white;
    }

}