@use "../../assets/scss/abstracts/index" as *;

.app-selection-portal { 
    background:  $adminLoginBg  url("../../assets/image/loginbackground.png") no-repeat center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    // row-gap: 54px;
    row-gap: clamp(20px, 6.9vh, 54px);
     @media (max-width: 768px) {
        background-image: none;
        padding: 10px;
    }
  
    .app-icon {
        .logo-icon {
          width: 194px;
          height: 56px;
        }
      }
}



.app-selection-portal-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    // min-width: 524px;
    //  height: 296px;
    padding: clamp(20px, 12.3vh, 92px) 72px;
    background: $white;
    box-shadow: 0px 14px 14px rgba(207, 215, 222, 0.12);
    border-radius: 10px;
    @media (max-width: 768px) {
        padding: 20px;
        min-height: 277px;
        border-radius: 20px;
        width: calc(100% - 36px);
        max-width: 400px;
    }
    .selection-portal-heading{
        font-weight: $font-weight-bold;
         font-size: $text-xxl-small-plus;
         color: $black;
       @media (max-width: 768px) {
             margin-bottom: 30px;
             font-size: $text-xl-plus;
            }
         }
 
     .selection-portal-sub-heading {
         color:$primaryColor;
          font-size: $text-lg;
          font-weight: $font-weight-regular;
         line-height: 24px;
         width: 65%;
         text-align: center;
         @media (max-width: 768px) {
             font-size: $text-sm-plus;
         }
     }
    .selection-portal-button-holder {
        display: flex;
        gap: 16px;
        margin-top: 30px;
        align-items: center;
        justify-content: center;
        @media (max-width: 450px) {
          flex-direction: column;
        }

        .emp-btn,.data-btn {
            padding: 30px;
            background: $white;
            border-radius: 10px;
            border: 1px solid $adminBgPrimaryLighter-2;
             font-size: $text-xxl;
            font-weight: $font-weight-bold;
            &:hover {
                border: 1px solid $adminBgPrimaryLighter-2;
                background: rgba(231, 240, 251, 0.60);

                }
            @media (max-width: 768px) {
                padding: 20px;
                font-size: 16px;
            }
           @media (max-width: 450px) {
              min-width: 100%;
              
            }
            .user-icon,
            .system-icon {
                color:$adminBgPrimaryLighter;
                width: 32px;
                height: 32px;
            }
       
        }

    }

   

}