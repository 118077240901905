@use '../../../assets/scss/abstracts/' as *;

.checkbox-wrapper{
    position: relative;    
    padding-bottom: 16px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 24px;
      }
    input[type="checkbox"] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        width: 18px;
        height: 18px;
        border-radius: 4px;
        margin: 0 12px 0 0;
        border: 0.15em solid $black;
        outline: none;
        cursor: pointer;
        flex-shrink: 0;
        &:checked {
            background-color: $black;
            border-color: $black;
            position: relative;
            &::before{
                content: "";
                color: #fff;
                position: absolute;
                left: 3px;
                top: 5px;
                width: 10px;
                height: 5px;
                border-left:2px solid $white;
                border-bottom:2px solid $white;
                display: block;
                transform: rotate3d(1, 1, 8, -47deg);
            }
            & + .checkbox-label{
                font-weight: $font-weight-medium;
            }
        }
    }
   .app-checkbox{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        line-height: normal;
        display: inline-flex;
        .checkbox-label{
            color: $black;
            font-size: 12px;
            line-height: 1.2;
            cursor: pointer;
            position: relative;
            top: 2.7px;
            &.relevancy{
                position: relative;
                padding-left: 22px;
                &::before{
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left: 0;
                    top: -2px;
                    display: inline-block;
                    border-radius: $border-radius-rounded;
                    content: '';
                }
                &.type1{
                    &::before {
                        background-color: $adminTilePink200;
                    }
                }
                &.type2{
                    &::before {
                        background-color: $adminTileBlue200;
                    }
                }
                &.type3{
                    &::before {
                        background-color: $adminTileGray200;
                    }
                }
            }
        }
    }
}