@use "../../../../assets/scss/abstracts/" as *;
.user-notification-dropdown {
  display: flex;
  padding-right: 14px;
  position: relative;
  top:13px;
  @media (max-width:768px){
    top: 12px;
    padding-right: 1px;
  }
  .app-btn {
    padding: 0px;
    margin: 0px;
  }
  .notification-icon{
    width:20px;
    height: 20px;
    color: $primaryColor;
  }
  &.dropdown-open {
    .mega-sub-menu {
      opacity: 1;
      pointer-events: all;
    }
    .notification-nav .menu-arrow {
      display: block;
      @media (max-width:991px) {
        display: none;
      }
      &::before {
          content: "";
          width: 23px;
          height: 8px;
          position: absolute;
          box-shadow: 0px 0px 16px rgb(0 0 0 / 23%);
          z-index: 1;
          clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
          left: 5px;
          top: 41px;
          background-color: $white;
          z-index: 7;
          box-shadow: 0px 10px 30px 3px rgba($blackBoxShadow, 0.15);
      }
      
    }
    .nav-dropdown-btn {
      background: $lightBlue;
    }
    .notification-nav.active {
      &::before {
        display: none;
      }
    }
  }
  .notification-nav {
    position: relative;
    .menu-arrow {
      display: none;
    }
  }
  .nav-dropdown-btn {
    padding: 6px;
    background: transparent;
    border-radius: 6px;
  }
  .sub-menu-header-menu-wrapper {
    position: absolute;
    top: 49px;
    left: -41px;
    // flex-flow: column wrap;
    flex-direction: column;
    width: 415px;
    display: flex;
    background: $white;
    box-shadow: 0px 10px 30px 3px rgba($blackBoxShadow, 0.15);
        box-shadow: 0px 10px 30px 3px rgb(5 16 55 / 15%);
    border-radius: 12px;
    z-index: 6;
    overflow: hidden;
    @media only screen and (max-width: 991px) {
      position: fixed;
      left: 0;
      top: 0;
      flex-grow: 1;
      height: 100%;
      width: 100%;
      
      @media only screen and (max-width: 991px){
        background:$bgColor9;
      }
    }
    .notification-header {
      padding: 24px 24px 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($black, 0.05);
     
      @media only screen and (max-width: 991px){
        margin-bottom: 8px;
        background-color: $white;
        padding: 19px 21px 19px 19px;
      }
      .notification-header-title{
        display: flex;
        align-items: center;
      }
      .back-arrow {
        display: none;
        @media only screen and (max-width: 991px) {
          display: block;
        }
      }
      .back-arrow-icon{
        fill:none;
      }
      .page-header {
        font-weight: $font-weight-semi-bold;
        font-size: $text-xl;
        line-height: 22px;
        color: $black;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 991px) {
          margin-left: 14px;
        }
      }
      .status-count {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        font-size: $text-sm;
        min-width: 25px;
        height: 23px;
        text-align: center;
        line-height: 15px;
        color: $white;
        font-weight: $font-weight-medium;
        position: relative;
        margin-left: 10px;
        background: $fancyBg2;
        border-radius: 70px;
        flex-shrink: 0;
      }
    }
    .notification-main-content {
      max-height: 293px;
      overflow: auto;
      @media only screen and (max-width: 991px) {
        overflow: auto;
        max-height: fit-content;
        background-color: $white;
      }
      .notification-item {
        font-weight: $font-weight-regular;
        font-size: $text-md;
        line-height: 22px;
        color: $darkBrown;
        padding-top: 16px;
        padding-bottom: 12px;
        margin-left: 24px;
        margin-right: 24px;
        border-bottom: 1px solid rgba($black, 0.05);
        background: $white;
        .bold-name {
          font-weight: $font-weight-semi-bold;
          color: $brownShade;
          word-break:break-word;
        }
      }
      .notification-date {
        font-weight: $font-weight-regular;
        font-size: $text-sm;
        line-height: 15px;
        color: $lightDark;
        display: inline-block;
        position: relative;
        background: $white;
        &.date-check {
          margin-left: 16px;
          &::before {
            content: "";
            position: absolute;
            left: -10px;
            top: 7px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $lightDark;
          }
        }
        @media only screen and (max-width: 991px) {
          display: block;
        }
      }
    }
  }
  .mega-sub-menu {
    opacity: 0;
    pointer-events: none;
  }
  .notification-nav.active {
    &::before {
      content: "";
      position: absolute;
      top: 4px;
      right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $lightRed;
      border: 1px solid $white;
    }
  }
}
