@use "../../../src/assets/scss/abstracts/" as *;

:focus {
  outline: none;
}
:focus-visible {
  outline: 3px solid rgba($onFocus, 0.5);
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 5px;
  scrollbar-color: $bgColor10 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background-color: $bgColor18;
}

*::-webkit-scrollbar-thumb {
  background-color: $bgColor10;
  border-radius: $border-radius6;
  border: 3px solid $bgColor18;
}
.app-tab-wrapper {
  ul {
    @extend %flex-all-center;
    border-bottom: 1px solid $borderColor11;
    li {
      flex: 1;
      .app-btn {
        width: 100%;
        border: none;
        position: relative;
        color: $tertiaryColor;
        font-size: $text-lg;
        padding: 3px 28.5px 15px 28.5px;
        line-height: 1.2;
        &.active {
          color: $tabActive;
          font-weight: $font-weight-semi-bold;
          &::before {
            position: absolute;
            height: 2px;
            display: block;
            bottom: -1px;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            background-color: $tabActive;
            content: "";
          }
        }
        .count {
          @extend %flex-all-center;
          background-color: $bgColor13;
          height: 21px;
          width: 21px;
          border-radius: $border-radius-rounded;
          color: $tertiaryColor;
          margin-left: 8px;
          font-size: $text-md;
        }
      }
    }
  }
  &.app-tab-horizontal {
    nav {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      overflow-x: auto;
    }
  }
}
.app-tag {
  display: flex;
  padding: 3px 7px 3px 6px;
  border-radius: 80px;
  font-weight: $font-weight-medium;
  font-size: $text-xs-plus;
  line-height: 1.2;
  align-items: center;
  text-align: center;
  color: $white;
  width: fit-content;
  margin-left: 5px;
  gap: 1px;
  white-space: nowrap;
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    font-size: 7px;
  }
  &.expiry {
    background: $fancyBg1;
  }
  &.verify {
    background: $fancyBg3;
    color: $white;
  }
  &.active {
    color: $white;
    background: $tabActiveLighter;
  }
  .expiry-icon {
    margin-right: 3px;
  }
}
.icon-btn-text {
  .button-text {
    margin-left: 6px;
  }
}
.btn-group {
  @extend %flex-all-center;
  &.align-right {
    justify-content: flex-end;
    padding: 16px 0;
    gap: 8px;
    @media (max-width: 991px) {
      gap: 16px;
    }
  }
}
.option-dropdown-wrapper {
  position: absolute;
  &.active {
    .icon-option {
      color: $optionBtn;
      svg {
        // background-color: $bgColor15;
      }
    }
    .option-dropdown {
      display: block;
    }
  }
  .icon-option {
    border-radius: $border-radius3;
    color: $borderColor5;
    height: 18px;
    width: 18px;
    @media (max-width: 768px) {
      transform: rotate(90deg);
    }

    svg {
      width: 3px;
      height: 13px;
      @media (max-width: 768px) {
        width: 4px;
        height: 16px;
      }
    }
  }
  .option-dropdown {
    position: absolute;
    right: 0;
    top: 20px;
    border: 1px solid $borderColor10;
    border-radius: $border-radius3;
    min-width: 70px;
    background: $white;
    overflow: hidden;
    display: none;
    z-index: 3;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.07);

    box-shadow: 0px 30px 43px 0px rgba(0, 0, 0, 0.0365);

    box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.0762);

    ul {
      li {
        button {
          font-weight: $font-weight-medium;
          font-size: $text-sm;
          line-height: 1.2;
          display: block;
          width: 100%;
          padding: 8px 12px;
          text-align: left;
          transition: background ease 0.3s;
          border-radius: $border-radius0;
          background: $white;
          &:hover {
            background: $bgColor3;
          }
        }
      }
    }
  }
}
.admin-wrapper {
  .app-btn {
    padding: 6px 16px;
    border-radius: 6px;
    background: $adminfooter;
    border: 1px solid $adminfooter;
    gap: 8px;
    min-height: 32px;
    white-space: nowrap;
    font-weight: $font-weight-medium;
    @media (max-width: 1365px) {
      padding: 6px 10px;
      gap: 5px;
    }
    @media (max-width: 991px) {
      font-weight: $font-weight-medium;
      font-size: $text-sm;
    }
    &:hover {
      background-color: $bgColor17;
      border-color: $bgColor17;
    }

    &.primary {
      background: $adminBgPrimaryLighter;
      border: 1px solid $adminBgPrimaryLighter;
      &:hover {
        background: $adminBgPrimaryLight;
        border-color: $adminBgPrimaryLight;
      }
      &:disabled,
      &.disabled {
        border-color: $primaryBtnDisabledBg;
        background-color: $primaryBtnDisabledBg;
        color: $primaryBtnDisabled;
      }
      &.min-width {
        min-width: 110px;
      }
    }
    &.secondary {
      border: 1px solid $lightDark;
      background-color: $white;
      &.active {
        border-color: $adminBgPrimaryLighter;
      }
      &:hover {
        background-color: $bgColor18;
      }
      &:disabled,
      &.disabled {
        border-color: transparent;
        color: $disabled;
        background: $bgColor1;
        svg {
          color: $borderColor8;
        }

        .btn-text {
          color: $disabled;
        }
      }
    }
    &.filter-panel-icon {
      .filter-icon {
        color: $adminIconColor;
        width: 16px;
        height: 16px;
      }
      .btn-text {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    &.min-width {
      min-width: 110px;
    }
    &.hide-in-mobile {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .icon-btn-text {
    .button-text {
      margin-left: 0;
    }
  }
  .checkbox-wrapper {
    padding-bottom: 13px;
    &.pb-0 {
      padding-bottom: 0;
    }

    input[type="checkbox"] {
      border: 1px solid #abbacc;

      &:checked {
        background: $adminBgPrimaryLighter;
        border: 1px solid $adminBgPrimaryLighter;
      }
    }

    // .checkbox-label {
    //   margin-left: 6px;
    // }
  }
  .footer-btns {
    padding: 16px 24px 24px 24px;
    display: flex;
    column-gap: 12px;
    @media (max-width: 991px) {
      flex-grow: 1;
      gap: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 19px 24px 19px 24px;
    }
  }
  .footer-btns,
  .btn-group,
  .add-skill-footer-btn,
  .add-certification-footer-btn,
  .download-footer-btn {
    @media (max-width: 991px) {
      .app-btn {
        flex: 1;
        font-weight: $font-weight-semi-bold;
        padding: 14px 22px;
        min-height: 48px;
        font-size: $text-md;
      }
    }
  }
  .download-footer-btn {
    @media (max-width: 991px) {
      .app-btn {
        width: fit-content;
        flex-grow: 0;
      }
    }
  }
  .admin-filter-wrapper {
    .app-btn {
      @media (max-width: 991px) {
        min-height: 40px;
        min-width: 40px;
        // border-color: $adminSearchBorder;
      }
    }
  }
  .modal-wrapper {
    .modal-footer {
      border-top: 1px solid $borderColor14;
      background-color: $white;
      @media only screen and (max-width: 768px) {
        border-top: none;
      }
    }
  }
}
.skilllisting-checkbox-layout {
  .checkbox-wrapper {
    padding-bottom: 0;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }
  .checkbox-label {
    white-space: nowrap;
  }
}
.radio-btn-card {
  border: 1px solid $bgColor15;
  border-radius: 5px;
  background-color: $white;
  padding: 10px;
  .radio-btn-wrapper {
    padding: 0;
  }
}
.inline-radio-group {
  display: inline-flex;
  gap: 16px;
}
.flex-jsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.switches-container {
  width: 75px;
  min-width: 75px;
  height: 20px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  line-height: 3rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 85px;
    min-width: 85px;
    height: 29px;
  }
  .switch-track {
    display: block;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    .switch-wrapper {
      height: 100%;
      position: relative;
      padding-left: 20px;
      background-color: $bgColor25;
      border: 1px solid $borderColor18;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background ease 0.6s;

      .switch-icon-block {
        color: $borderColor18;
        background-color: $white;
        width: 17px;
        height: 16px;
        border-radius: 2px;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: all ease 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
          top: 5px;
          left: 3px;
        }
      }
    }
  }
  .switch-input {
    visibility: hidden;
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
  .switch-track-label {
    color: $bgColor21;
    font-size: $text-xs;
    font-weight: $font-weight-semi-bold;
    transition: color ease 0.5s;
    &.active {
      display: none;
    }
  }
  .switch-input:checked ~ .switch-wrapper {
    padding-left: 0;
    padding-right: 22px;
    background-color: $fancyBg10;
    .switch-track-label {
      color: $white;
      &.active {
        display: block;
      }
      &.inactive {
        display: none;
      }
    }
    .switch-icon-block {
      transform: translateX(53px);
      @media screen and (max-width: 767px) {
        transform: translateX(60px);
      }
    }
  }
}
//sort icon
.sortWrapper {
  display: flex;
  gap: 2px;
  align-items: center;
  white-space: nowrap;
  .sort-ascend {
    color: $tertiaryColor;
    .sort-highlight {
      height: 18px;
      width: 18px;
    }
    &.sort-descend {
      svg {
        transform: rotate(180deg);
        height: 18px;
        width: 18px;
      }
    }
    &.selectedColor {
      color: $white;
      .sort-highlight {
        background-color: #307ce1;
        border-radius: 4px;
      }
    }
  }
}

.btn-view-mail {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  border: 1px solid $skyBlue;
  border-radius: 5px;
  background: $white;
  padding: 3px 8px;
  color: $tabActiveLighter;
  font-size: $text-sm;
  font-weight: $font-weight-regular;
  cursor: pointer;
  &:hover{
    background-color: #F2F7FD;
  }
  .eye-icon {
    width: 16px;
    height: 15px;
  }
}
.app-c-tab {
  display: flex;
  align-items: center;

  &.type-2 {
  }

  &.placement-left {
    justify-content: flex-start;
  }

  &.placement-fit {
    justify-content: space-between;
  }
  .app-c-tab-item {
    background: none;
    padding: 10px;
    line-height: 1.2;
    font-size: $text-lg;
    border-radius: 0;
    border: none;
    position: relative;
    cursor: pointer;
    &::before {
      position: absolute;
      content: "";
      width: 0;
      background-color: $urlColor;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      transition: all ease 0.2s;
    }
    &.active {
      color: $adminBgPrimary;
      font-weight: $font-weight-semi-bold;
      &::before {
        width: 100%;
      }
      &:hover {
        &::before {
          background-color: $urlColor;
        }
      }
    }
    &:hover {
      &::before {
        width: 100%;
        background-color: $borderColor5;
      }
    }
    .count {
      color: $adminBgPrimary;
      font-size: $text-sm;
      font-weight: $font-weight-regular;
    }
  }
}
