@use "../../../assets/scss/abstracts/index" as *;

.icon-fill-transparent {
    fill: transparent;
}
.svg-icon {
    display: block;
    pointer-events: none;
    width: 12px;
    height: 12px;
    &.sm{
        width: 8px;
        height: 8px;
    }
    &.lg{
        width: 17px;
        height: 17spx;
    }
}

.icon-stroke {
    stroke: currentColor;
}

.icon-fill {
    fill: currentColor;
}
.icon-fill-white,
.icon-stroke-white {
    fill: $white;
}
.folder-phase1{
    fill: var(--foldercolor1);
}
.folder-phase2{
    fill: var(--foldercolor2);
}
.folder-phase3{
    fill: var(--foldercolor3);
}
.folder-phase4{
    fill: var(--foldercolor4);
}
.folder-phase5{
    fill: var(--foldercolor5);
}
.folder-phase6{
    fill: var(--foldercolor6);
}
.folder-icon{
    --foldercolor1:#2E89AC;
    --foldercolor2:#409FBC;
    --foldercolor3:#41B3E2;
    --foldercolor4:#5ECDEF;
    --foldercolor5:#B2E1FC;
    --foldercolor6:#379FC8;
}
.folder2-phase1{
    fill: var(--foldercolor1);
}
.folder2-phase2{
    fill: var(--foldercolor2);
}
.folder2-phase3{
    fill: var(--foldercolor3);
}
.folder2-phase4{
    fill: var(--foldercolor4);
}
.folder2-phase5{
    fill: var(--foldercolor5);
}

.folder2-icon{
    --foldercolor1:#DCF2FF;
    --foldercolor2:#83C4E9;
    --foldercolor3:#83C4E9;
    --foldercolor4:#9BD1F0;
    --foldercolor5:#83C4E9;
}

