@use "../../../../../assets/scss/abstracts/" as *;

.certification-grid-view {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  padding-top: 8px;
  @media (max-width:991px) {
    padding-top: 0;
  }
  .certification-layout {
    flex: 1 1 1px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 8px 16px 0 16px;

    @media (max-width:991px) {
      padding-top: 0;
    }

    .certification-layout-header {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $adminPrimaryGray;
    }

    .layout {
      gap: 16px;
      margin-top: 8px;

      @media (max-width:991px) {
        --responsive-box-count: 2;
      }

      .certification-item-set {
        border-radius: 8px;
        position: relative;

        .certification-item-link {
          position: relative;
          padding: 12px 12px 14px 16px;
          display: flex;
          // row-gap: 25px;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          text-decoration: none;
          top: 0;
          left: 0;

          &:focus-visible {
            border-radius: 8px;
          }
        }

        .certification-item-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          word-break: break-word;
          color: $black;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: $black;
          margin-bottom: 25px;
        }

        .certification-num-edit-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .certification-count {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: $black;

            .certification-added {
              font-weight: 400;
              margin-left: 4px;
            }
          }
        }

        .edit-icon-btn {
          border: 1px solid rgba($darkBrown, 0.14);
          border-radius: 4px;
          padding: 3px;
          position: absolute;
          right: 12px;
          bottom: 12px;
          z-index: 2;
          transition: background ease 0.2s;

          &:hover {
            background-color: rgba($darkBrown, 0.14);
          }

          @media (max-width: 991px) {
            border: none;
            background-color: rgba($color: $black, $alpha: 0.05);
            border-radius: 16px 0 10px 0;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 33px;
            height: 33px;
          }

          .edit-icon {
            fill: none;
            width: 16px;
            height: 16px;
            color: $darkBrown;
          }
        }
      }
    }
  }

  .purple-shade {
    .certification-item-set {
      background: $adminTilePink;
    }
  }

  .blue-shade {
    .certification-item-set {
      background: $adminTileBlue;
    }
  }

  .gray-shade {
    .certification-item-set {
      background: $adminTileGray;
    }
  }

  .add-certification-footer-btn {
    width: calc(100% - 8px);
    padding: 19px 16px 25px 16px;
    background: $white;
    box-shadow: 0px -1px 11px rgba($black, 0.1);
    z-index: 4;
    position: fixed;
    margin: 0 auto 0 auto;
    bottom: 4px;
    left: 0;
    right: 0;
    display: none;

    &.with-bg {
      .app-btn {
        width: 100%;
        color: $white;
        border: 1px solid $adminBgPrimaryLighter;
        background: $adminBgPrimaryLighter;
        border-radius: 6px;
      }
    }

    @media (max-width: 991px) {
      display: flex;
    }
  }
}