@use '../../../assets/scss/abstracts/' as *;

.radio-btn-wrapper{
    position: relative;    
    padding-bottom: 16px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 24px;
      }
    input[type="radio"] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        width: 18px;
        height: 18px;
        border-radius: $border-radius-rounded;
        margin:0 12px 0 0;
        border: 0.15em solid $borderColor5;
        outline: none;
        cursor: pointer;
        flex-shrink: 0;
        &:checked {
            background-color: $adminBgPrimaryLighter;
            border-color: $adminBgPrimaryLighter;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 8px;
                height: 8px;
                border-radius: $border-radius-rounded;
                display: block;
                background-color: $white;
            }
            & + .radio-btn-label{
                font-weight: $font-weight-medium;
            }
        }
    }
   .app-radio-btn{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        line-height: normal;
        display: inline-flex;
        .radio-btn-label{
            color: $black;
            font-size: 12px;
            line-height: 1.2;
            cursor: pointer;
            position: relative;
            top: 2.8px;
        }
    }
}