@use "../../../src/assets/scss/abstracts/index" as *;

.select-skill-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;


    @media (max-width:991px) {
        z-index: 9;
    }

    &.mobile {
        &.active {
            display: none;

            @media (max-width:991px) {
                display: block;

            }
        }
    }

    &.active {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        background: $backDrop1;
        z-index: 8;
        width: 100%;
        height: 100%;


    }
}

.select-skill-level {

    position: fixed;
    top: calc(var(--top) - var(--toggle-adjust, 0px));
    left: calc(var(--left));
    width: 323px;
    background-color: $white;
    border: 1px solid $borderColor6;
    border-radius: $border-radius5;
    transition: top ease 0.2s;
    z-index: 9;
    display: none;

    &.active {
        @extend %flex-full-height;
    }

    @media (max-width:991px) {
        border-radius: $border-radius8 $border-radius8 0 0;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
        border-width: 10px;
        border-style: solid;
        border-color: transparent $borderColor6 transparent transparent;
        left: -20px;
        top: calc(19px + var(--toggle-adjust));
        transition: top ease 0.2s;

        @media (max-width:991px) {
            display: none;
        }
    }

    &::after {
        left: -19px;
        border-color: transparent $white transparent transparent;
    }

    @media (max-width:991px) {
        top: unset !important;
        left: 0 !important;
        bottom: 0;
        position: fixed;
        width: 100%;
    }
}

.skill-def-wrapper {
    h5 {
        font-weight: $font-weight-medium;
        font-size: $text-sm;
        line-height: 1.2;
        margin-bottom: 16px;
    }
}

.select-skill-head {
    padding: 20px 24px 0 24px;
    display: none;

    @media (max-width:991px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h3 {
        line-height: 1.5;
        font-size: $text-xl;
        font-weight: $font-weight-medium;
    }
}

.select-skill-content {
    padding: 16px;

    @media (max-width:991px) {
        padding: 24px 24px 0px 24px;
    }

    .btn-group.align-right {
        gap: 0;

        @media (max-width:991px) {
            justify-content: space-between;
        }

        .app-btn {
            width: 100%;
        }

        .primary {
            margin-left: 8px
        }
    }
}

.skill-def-head {
    @extend %flex-all-center;
    justify-content: space-between;
    padding: 16px;
    gap: 8px;
    cursor: pointer;

    @media (max-width:991px) {
        justify-content: center;

    }

    .skill-def-title {
        margin: 0;
        font-weight: $font-weight-medium;
    }
}

.skill-def-block {
    background-color: $bgColor14;
    border-radius: 0 0 $border-radius5 $border-radius5;

    svg {
        transform: rotate3d(0, 0, 1, 180deg);
    }

    &.active {
        .skill-def-content {
            display: block;
        }

        .link-btn {
            svg {
                transform: rotate3d(0, 0, 1, 0deg);
            }
        }
    }

    .toggle-text {
        @media (max-width:991px) {
            display: none;
        }
    }

}

.skill-def-content {
    padding: 0;
    display: none;
    max-height: 180px;
    overflow-y: auto;

    ul {
        li {
            color: $primaryColor;
            line-height: 1.45;
            font-size: $text-xs-plus;
            display: block;
            margin-bottom: 17px;
            position: relative;
            padding-left: 29px;
            max-height: 86px;
            overflow: auto;

            &::before {
                display: block;
                content: '';
                position: absolute;
                left: 15px;
                top: 3.5px;
                width: 7px;
                height: 7px;
                border: 1.5px solid $borderColor9;
                border-radius: $border-radius-rounded;
            }

            .bold {
                font-weight: $font-weight-semi-bold;
                color: $black;
            }
        }
    }
}