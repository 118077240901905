@use "../../../../assets/scss/abstracts/palette" as *;
@use "../../../../assets/scss/abstracts" as *;
.layout-footer{
    border-top: 1px solid $borderColor1;
    padding: 24px 32px;
    text-align: center;
    color: $black;
    @media (max-width:991px) {
        display: none;
    }
    .main-footer-content{
        font-weight: $font-weight-regular;
        font-size: $text-md;
        line-height: 17px;
        color: $black;
        padding-bottom: 12px;
    }
    .icon-btn-text{
        .button-text{
            margin-left: 6px;
        }
    }
}