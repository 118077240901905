@use "../../../../../assets/scss/abstracts/" as *;

.layout-holder{
    display:grid;
}
.layout-holder.fr-2{
        display: grid;
        grid-template-columns: 205px 1fr;
        column-gap: 16px;
        @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            }
}
.layout-item{
    margin-bottom: 24px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
        }
    .heading-type-1{
        font-weight: $font-weight-regular;
        font-size: $text-sm;
        color: $primaryColor;
    }
    .description-type-1{
        font-weight: $font-weight-medium;
        font-size: $text-md;
        line-height: 150%;
        color: $black;
        margin-top: 4px;
        word-break: break-all;
        &.link {
            color: $urlColor;
            &:hover{
                color: $urlColorHover;
            }
        }
        .value-tag-holder{
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .tag-holder{
                padding: 8px 12px;
                width: fit-content;
                background: $lightWhiteShade;
                border: 1px solid $skyBlue;
                border-radius: $border-radius6;
                font-weight: $font-weight-regular;
                font-size: $text-sm;
                line-height: 12px;
                color: $black;
            }
        }
    }

}