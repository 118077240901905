@use "../../../../../assets/scss/abstracts/" as *;

.chart-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.zoom-slider-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 26px 22px 16px;
    align-items: center;

    @media (max-width:991px) {
      justify-content: center;
      .zoom-slider-holder{
        display: none;
      }
     
    }
}
.range-slider-holder{
    display: flex;
    gap: 5px;  
}
.range-slider-name{
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: $font-weight-semi-bold;
    color: $adminPrimaryBlack;
}

.range-values-data{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  gap:2px;

  .rangeValues-heading{
    color: #47546C;
   font-size: 12px;
    font-weight: $font-weight-medium;
  }
   .rangeValues{
    color: $adminPrimaryBlack;
    font-weight: $font-weight-medium;
    font-size: 16px;
   } 
  

}
.range{
  border-left:1px solid  $bgColor13 ;
  border-right: 1px solid  $bgColor13;
  padding: 18px 14px 0; 
  z-index: 5;
}
.range-slider {
  height: 1px;
  position: relative;
  border-top: 3px  dashed $adminBgPrimaryLight;
  z-index: 8;
}

.slider-middle{
  position: absolute;
  height: 20px;
  background-color: rgba(167, 186, 218, 0.91);;
  top:-9px;
  border-radius: 9px;
  border-top: 2px solid  $adminBgPrimaryLight; 
  border-bottom: 2px solid  $adminBgPrimaryLight;
  z-index: 9;
  &:hover{
    cursor: pointer;
  }
}
.slider-range-input {
  position: relative;
  min-width: 200px;
  height: 10px;
  @media (max-width:400px) {
    min-width: 150px;
}
}
.slider-input{
  position: absolute;
  width: calc(100% - 24px);
  height: 5px;
  top: -3px;
  left: -2px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 10;
}
@-moz-document url-prefix() {
  .slider-range-input input {
 top:-7px;
   
  }
}

.input-right{
  right: -2px;
  left: auto;
}
.slider-range-input  .slider-input::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border:8px solid $adminBgPrimaryLight;
  background-color: $white;
  pointer-events: auto;
  -webkit-appearance: none;
}
.slider-range-input .slider-input::-moz-range-thumb {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 8px solid $adminBgPrimaryLight;
  background-color: $white;
  pointer-events: auto;
  -moz-appearance: none;
}


.range-slider-data{
  display: flex;
  gap:10px
}
.range-slider-tooltip{
display: flex;
justify-content: center;
align-items: center;
position: absolute;
border-radius: 6px;
color: $white;
background-color: $black;
font-weight: $font-weight-medium;
top:-53px;
max-width: 75px;
width: 100%;
height: 30px;
      &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: $black;
            transform: rotate(45deg) translateX(50%);
            left: 43%;
            top: 83%;
        }
}





.range-value {
    color: $darkBrown;
    font-size: $text-md;
    font-weight: $font-weight-medium;
    margin-left: 7px;
    display: inline-block;
    min-width: 50px;
}

.zoom-slider-holder {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    gap: 10px;
    padding-bottom: 4px;

    .zoom-control-btn {
        color: $darkBrown;
        width: 14px;
        height: 14px;
        position: relative;

        &.out {
            bottom: -4px;
        }

        &.in {
            bottom: 4px;
        }

        svg {
            width: 14px;
            stroke-width: 1.5;

            &.zoom-out {
                height: 2px;
            }

            &.zoom-in {
                height: 14px;
            }
        }
    }
}

.range-input-wrapper {
    display: inline-block;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: $bgColor13;
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        clip-path: polygon(100% 5%, 0% 100%, 100% 100%);
        z-index: 1;

    }
}

.range-input {
    width: 176px;
    height: 17px;
    -webkit-appearance: none;
    background: none;
    outline: none;
    border-radius: 0;
    position: relative;
    z-index: 2;


}

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 25px;
    border-radius: $border-radius1;
    background: $darkBrown;
    cursor: pointer;
    position: relative;
    bottom: -4px;
    z-index: 3;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 25px;
    border-radius: $border-radius1;
    background: $darkBrown;
    cursor: pointer;
    position: relative;
    z-index: 3;
    border: 0 none;
    outline: none;
    transform: translateY(4px);
}