@import "../../../assets/scss/abstracts/";
.pagination-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width:991px) {
      column-gap: 20px;
    }
    .app-btn.arrow-btn-active,  .app-btn.arrow-btn-inactive{
      padding: 0;
      background-color: transparent;
      border: 0px;
      &:hover{
        background-color: transparent;
      }
      &.arrow-btn-inactive {
        .left-move-icon, .right-move-icon {
          color: $admingrayDot;
        }
      }
      &.arrow-btn-active {
        .right-move-icon,.left-move-icon {
          color: $headingColor;
        }
      }
    }
    .pagination-num {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $headingColor;
      width: 24px;
      &.active {
        color: $adminBgPrimaryLighter;
      }
    }
  }