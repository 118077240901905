@use "../../../../../assets/scss/abstracts/" as *;

.user-details-section {
  display: flex;
  flex-direction: column;
  height: 100%;

  .user-details-footer {
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-radius: 0 0 6px 6px;
    padding: 6px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    @media only screen and (max-width: 768px) {
      display: none;
    }

    .item-view-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      position: relative;

      .item-view-details {
        color: $tertiaryColor;
        font-size: $text-md;
        font-weight: $font-weight-regular;
      }
    }
  }
}
.user-details-Wrappper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  .user-details-Table {
    display: flex;
    flex-direction: column;
    flex: 1 1 1px;
    overflow-y: auto;
    background-color: $white;
    .nodata-table {
      flex: 1 1;
      height: 100%;
    }
        table {
          tbody {
            tr {
              td{
                height: 43px;
              }
            }
          }
        }

    &.filter {
      table {
        &.nodata-table {
          flex: 1 1;
          height: 100%;
        }
        th {
          &.col-skillType {
            width: 15%;
          }
        }
      }
    }
    &.certification-filter {
      table {
        th {
          &.col-employee {
            width: 16%;
            min-width: 200px;
          }
          &.col-designation {
            width: 14%;
            min-width: 200px;
          }
          &.col-certificationname {
            width: 17%;
            min-width: 200px;
          }
          &.col-certificationsissuer {
            width: 16%;
            min-width: 180px;
          }
          &.col-certificationsstatus {
            width: 16%;
            min-width: 140px;
          }
        }
      }
    }
    &.domain-filter {
      table {
        th {
          &.col-employee {
            width: 16%;
            min-width: 200px;
          }
          &.col-designation {
            width: 14%;
            min-width: 200px;
          }
          &.col-department {
            width: 12%;
            min-width: 100px;
          }
        }
      }
    }
    &.email-log-wrapper {
      table {
        th {
          &.col-date {
            min-width: 120px;
          }
          &.col-subject {
            width: 16%;
            min-width: 485px;
          }
          &.col-total-recipients {
            width: 14%;
            min-width: 140px;
          }
          &.col-status {
            width: 12%;
            min-width: 140px;
          }
          &.col-reason {
            width: 25%;
            min-width: 130px;
          }
          &.col-delivered-recipients {
            width: 12%;
            min-width: 163px;
          }
          &.col-undelivered-receipent {
            width: 12%;
            min-width: 180px;
          }
          &.col-action {
            width: 8%;
            min-width: 120px;
          }
        }
      }
      tbody {
        .subject-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        .icon-wrapper {
          display: flex;
          gap: 10px;
          .go-details {
            color: $tabActiveLighter;
            background: none;
            &:hover {
              color: $primaryColor;
            }
          }
        }
        .email-icon,
        .eye-icon,
        .info-icon {
          color: #307ce1;
          width: 20px;
          height: 20px;
          fill: white;
        }
        .email-icon{
          color: #6B6B6B;
        }
        .status-update {
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 4px 8px 4px 8px;
          border-radius: 16px;
          gap: 5px;
          &.success {
            border: 1px solid $statusGreen300;
            background-color: $statusGreen100;
            color: $statusGreen800;
          }
          &.pending {
            border: 1px solid $statusOrange300;
            background-color: $statusOrange100;
            color: $statusOrange800;
          }
          &.in-progress{
            border: 1px solid $statusBlue300;
            background-color: $statusBlue100;
            color: $statusBlue800;
          }
          &.failed {
            border: 1px solid $statusPink300;
            background-color: $statusPink100;
            color: $statusPink800;
          }
          .status-name {
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
          }
          .status-icon {
            .svg-icon {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    table {
      @media (max-width: 991px) {
        min-width: 840px;
      }
      th {
        position: sticky;
        top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: $bgColor20;
        background: $bgColor3;
        font-size: $text-sm-plus;
        font-weight: $font-weight-bold;
        padding: 13px 16px;
        vertical-align: middle;
        z-index: 2;
        &.select {
          width: 30px;
          padding-left: 30px;
        }
        &.col-no {
          width: 5%;
        }
        &.col-id {
          width: 5%;
        }
        &.sl-no {
          width: 92px;
          height: 46.5px;
          padding-left: 30px;
        }
        &.col-employee {
          width: 16%;
          min-width: 240px;
        }
        &.col-designation {
          width: 14%;
          min-width: 220px;
        }
        &.col-department {
          width: 17%;
          min-width: 100px;
        }
        &.col-skillname {
          width: 12%;
          min-width: 190px;
        }
        &.col-skilllevel {
          width: 12%;
          min-width: 160px;
        }
        // .sort-ascend {
        //   color: $tertiaryColor;
        //   .sort-highlight{
        //     height: 18px;
        //     width: 18px;
        //   }
        //   &.sort-descend {
        //     svg{
        //       transform: rotate(180deg);
        //       height: 18px;
        //       width: 18px;
        //      }
        //   }
        //   &.selectedColor {
        //     color: $white;
        //     .sort-highlight{
        //                   background-color: #307CE1;
        //                   border-radius: 4px;

        //     }
        //   }
        // }
      }
      tbody {
        .userwrapper {
          display: flex;
          align-items: center;
          gap: 10px;

          .profile-img-holder {
            width: 30px;
            height: 30px;
            border-radius: $border-radius-rounded;
            img {
              width: 30px;
              height: 30px;
              border-radius: $border-radius-rounded;
            }
          }

          .page-img {
            width: 36px;
            height: 36px;
            border-radius: $border-radius-rounded;
          }
        }
      }
      td {
        &.nodata-container {
          vertical-align: middle;
          text-align: center;
          width: 100%;
          height: 100%;
          font-size: 17px;
          font-weight: 500;
          color: grey;
        }
      }
    }
    p{
      padding-top: 24px;
      padding-left: 16px;
      @media (max-width: 991px) {
        padding-top: 0;
        padding-left: 0;
      }
    }
  }

  // .user-details-footer {
  //   flex-shrink: 1;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   background-color: $white;
  //   border-radius: 0 0 6px 6px;
  //   padding: 6px 20px;
  //   border-top: 1px solid rgba(0, 0, 0, 0.05);

  //   .item-view-section {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     gap: 8px;
  //     position: relative;

  //     .item-view-details {
  //       color: $tertiaryColor;
  //       font-size: $text-md;
  //       font-weight: $font-weight-regular;

  //     }
  //   }
  // }
}

.admin-c-modal.categoryModal {
  .modal-wrapper {
    .modal-header.header-wrapper {
      flex-direction: column;
      gap: 0;
      h4 {
        font-weight: $font-weight-semi-bold;
      }
      .modal-sub-header {
        font-size: $text-md;
        font-weight: $font-weight-regular;
      }
      .modal-header-bottom {
        padding-top: 0;
      }
    }
  }
}

.user-details-mobile-Wrappper {
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
  }

  .selectBoxWrapper {
    width: 20%;
    margin-left: 16px;
  }

  .user-section {
    flex: 1 1 1px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .user-skill-details {
      padding: 16px;
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      gap: 8px;
      &:first-child {
        border-top: 0px;
      }
      .image-section {
        img {
          width: 36px;
          height: 36px;
          border-radius: $border-radius-rounded;
        }
      }

      .page-img {
        width: 36px;
        height: 36px;
        border-radius: $border-radius-rounded;
      }

      .user-detail-section {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .user-title {
          color: $bgColor20;
          font-size: $text-sm;
          font-weight: $font-weight-bold;
          text-transform: capitalize;
        }

        .user-sub-details {
          display: flex;
          gap: 8px;

          .user-id {
            color: $bgColor20;
            font-size: $text-sm;
            font-weight: $font-weight-regular;
          }
          .user-designation {
            position: relative;
            padding-left: 8px;
            color: $bgColor20;
            font-size: $text-sm;
            font-weight: $font-weight-regular;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              background-color: $admingrayDot;
              border-radius: 50%;
              left: 0;
              top: 5px;
            }
          }
        }

        .skill-level {
          color: $black;
          font-size: $text-sm;
          font-weight: $font-weight-medium;
        }

        .box-holder {
          display: flex;
          border-radius: 4px;
          .left-section {
            padding: 4px 8px;
            border: 1px solid $borderColor13;
            border-radius: 4px 0 0 4px;
            color: $fancyBg8;
            font-size: $text-sm;
            font-weight: $font-weight-medium;
          }

          .right-section {
            padding: 4px 8px;
            color: $secondaryColor;
            border: 1px solid $borderColor15;
            border-radius: 0 4px 4px 0;
            background-color: $bgColor19;
            font-size: $text-xs;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
.mobile-pagination {
  background-color: white;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
}
.user-details-Table {
  overflow-x: auto;
  position: relative;
}
.user-details-Table td.fixed-column{
  background-color: white;
}
.user-details-Table th.fixed-column, 
.user-details-Table td.fixed-column {
  position: sticky;
  right: 0;
  z-index: 1;
}

.user-details-Table th.fixed-column:nth-last-child(2), 
.user-details-Table td.fixed-column:nth-last-child(2) {
  right: 100px;
}

.user-details-Table th.fixed-column:last-child, 
.user-details-Table td.fixed-column:last-child {
  right: 0;
}

// center align
.col-undelivered-receipent .sortWrapper{
    justify-content: center;
  }
.col-status .sortWrapper{
    // margin-left: 5px;
  }
.col-total-recipients .sortWrapper{
    justify-content: center;
  }
.col-delivered-recipients .sortWrapper{
    justify-content: center;
  }