@use "../../../assets/scss/abstracts/index" as *;

.admin-c-modal {
  @extend %screen-overlay;
  @extend %flex-all-center;
  flex-direction: column;
  padding: 16px;

  &.categoryModal {
    .modal-wrapper {
      .modal-header {
        padding-bottom: 12px;
        margin-bottom: 0;
      }

      .modal-body {
        padding: 0;
        overflow: hidden;
      }
    }
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    border-radius: $border-radius6;
    box-shadow: $modalBoxShadow1;
    max-height: calc(100vh - 40px);

    &.sm {
      max-width: 480px;
    }

    &.md {
      max-width: 640px;
    }

    &.extra-md {
      max-width: 780px;

      .modal-body {
        @media only screen and (max-width: 768px) {
          padding-bottom: 0;
          background: $white;
          padding: 21px 16px 16px 16px;
        }

        @media only screen and (max-width: 375px) {
          padding: 21px 5px 16px 5px;
        }
      }
    }

    &.placement-bottom {
      @media only screen and (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        width: 100%;
        border-radius: 24px 24px 0 0;

        .modal-header {
          justify-content: space-between;
          background-color: $white;
          margin-bottom: 0;
          border-radius: 24px 24px 0px 0px;

          .modal-header-top {
            .modal-header-wrapper {
              .icon-only.back-arrow {
                display: none;
              }

              h4 {
                color: $black;
                margin-left: 0;
                font-size: 16px;
                font-weight: 500;
              }
            }

            .icon-only.close-icon {
              display: block;

              .close-icon {
                display: block;
              }
            }
          }
        }

        .modal-body {
          padding-top: 0px;
        }
      }

      .modal-footer {
        @media only screen and (max-width: 768px) {
          border-radius: 0;
        }
      }
    }

    &.placement-full {
      @media only screen and (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        border-radius: 0;
        background: $bgColor9;
      }

      .modal-footer {
        &.hide-footer {
          display: none;
        }

        @media only screen and (max-width: 768px) {
          border-radius: 0;

          &.hide-footer {
            display: none;
          }
        }
      }
    }

    .modal-header.hide-header-bottom {
      h4 {
        font-weight: 600;
      }

      @media only screen and (max-width: 768px) {
        padding: 19px 24px 19px 24px;
      }
    }

    .modal-header {
      padding: 24px 24px 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;


      &.header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;
        border-radius: 12px 12px 0px 0px;


        .icon-only.back-arrow {
          display: none;
        }

        .user-count-added {
          padding: 7px 16px 8px 12px;
          display: inline;
          align-items: center;
          border-radius: 6px;
          font-weight: $font-weight-semi-bold;
          font-size: $text-md;
          line-height: 17px;
          text-align: center;
          color: $black;
          background: $adminSkyBlue;

          .count-name {
            font-weight: $font-weight-regular;
            font-size: $text-xs-plus;
            line-height: 13px;
            text-align: center;
            color: $black;
          }
        }

        &.purple-shade {
          background: $adminTilePink;

          .modal-header-wrapper .modal-sub-header {
            @media only screen and (max-width: 768px) {
              color: $greyish;
            }

          }

          .user-count-added {
            background: $adminLightPurple;
          }
        }

        &.blue-shade {
          background: $adminTileBlue;

          .user-count-added {
            background: $adminSkyBlue;
          }
        }

        &.gray-shade {
          background: $adminTileGray;

          .user-count-added {
            background: $adminTileGray;
          }
        }

        h4 {
          color: $headingColor;
          font-size: 24px;
          // line-height: 1.7;
          font-weight: $font-weight-medium;

          @media only screen and (max-width: 768px) {
            margin-left: 0px;
            font-size: 18px;
            font-weight: $font-weight-semi-bold;
          }
        }

        @media only screen and (max-width: 768px) {
          border-radius: 0;
        }
      }

      &.hide-header-bottom {
        .modal-header-bottom {
          display: none;
        }
      }

      .modal-header-top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media only screen and (max-width: 768px) {
          position: relative;
        }

        .modal-header-wrapper {
          display: flex;
          .modal-header-icon{
            display: flex;
            align-items: center;
            .profile-img-holder {
              width: 47px;
              height: 47px;
              border-radius: $border-radius-rounded;
              .folder-icon {
                color: $adminTilePink;
              }
              img{
                width: 47px;
                height: 47px;
                border-radius: $border-radius-rounded;
              }
            }
            }

         

          .modal-sub-header {
            font-weight: $font-weight-regular;
            font-size: $text-sm;
            line-height: 1;
            color: $greyish;
            margin-top: 4px;

            @media only screen and (max-width: 768px) {
              margin-top: 5px;
            }

            .sub-title-count {
              color: $black;
              margin-right: 2px;
              font-weight: $font-weight-semi-bold;
            }

          }
        }

        .modal-subheader-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .user-count-added {
            padding: 7px 16px 8px 12px;
            display: inline;
            border-radius: 6px;
            font-weight: $font-weight-semi-bold;
            font-size: $text-md;
            line-height: 17px;
            text-align: center;
            color: $black;
            background: $adminSkyBlue;

            .count-name {
              font-weight: $font-weight-regular;
              font-size: $text-xs-plus;
              line-height: 13px;
              text-align: center;
              color: $black;
            }
          }

        }
      }

      .modal-header-bottom {
        display: flex;
        width: 100%;
        flex-grow: 1;
        padding-top: 10px;

        @media only screen and (max-width: 768px) {
          padding-top: 19px;
        }

        .user-info-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            padding-top: 8px;
          }

          .info-section-left {
        padding-left: 58px;
         
            color: $adminPrimaryBlack;
            font-size: $text-sm;
      
            font-weight: $font-weight-medium;
            
            text-transform: capitalize;
          }

          .info-section-right {
            display: flex;
            gap: 4px;
            @media only screen and (max-width: 768px) {
              padding-left: 50px;
            }

          }


        }


        .user-sub-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .user-sort-icon {
            display: none;
                        @media only screen and (max-width: 768px) {
                          display: block;
                                       border: 1px solid #8390a8;
            background-color: #ffffff;
            position: relative;
                margin-left: auto;
                          &::after{
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: #EE6464;
                border-radius: 50%;
                content: "";
                top: -3px;
                right: -3px;
              }

            .filter-icon {
              width: 18px;
              height: 18px;
              color: #8390a8;
            }
            }

          }

          .user-search-btn-wrapper {
            display: flex;
            column-gap: 8px;

            .up-move-icon,
            .down-icon {
              width: 16px;
              height: 16px;
              fill: none;
            }

            .app-btn {
              gap: 0;

              .button-text {
                font-size: $text-sm;
                font-weight: $font-weight-medium;
                line-height: 15px;
                margin-left: 8px;

                &.mobile-text {
                  display: none;

                  @media only screen and (max-width: 768px) {
                    display: block;
                  }
                }

                &.desktop-text {
                  display: block;

                  @media only screen and (max-width: 768px) {
                    display: none;
                  }
                }
              }

              &.hide-on-mobile {
                @media (max-width: 768px) {
                  display: none;
                }
              }
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        margin-bottom: 0px;
        padding: 16px 16px 16px 16px;
        margin-bottom: 8px;
      }

      .icon-only {
        width: 18px;
        height: 18px;
        background: none;

        &.show-close {
          display: inline-flex;
          color: $darkBrown;

          .close-icon {
            display: block;
          }
        }

        .close-icon {
          @media only screen and (max-width: 768px) {
            width: 12px;
            height: 12px;
            color: $lightGrey;
          }
        }

        &:hover {
          color: $primaryColor;
        }
      }

      .folder-icon {
        width: 40px;
        height: 40px;
      }

      .back-arrow {
        display: none;

        @media only screen and (max-width: 768px) {
          display: block;
          color: $white;
        }

        .back-arrow-icon {
          fill: none;
        }
      }

      .close-icon {
        display: none;

        &.hide {
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    .modal-body {
      padding: 24px 24px 0px 24px;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;

      @media only screen and (max-width: 768px) {
        padding-bottom: 0;
        background: $white;
        padding: 15px 24px 16px 24px;
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: transparent;
      border-top: 1px solid rgba($black, 0.1);
      border-radius: 0 0 $border-radius6 $border-radius6;
      gap: 12px;

      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        background-color: $white;
      }

      &.space-between {
        justify-content: space-between;
        gap: 0;
      }

      .footer-msg {
        position: relative;
        padding-left: 23px;
        font-weight: $font-weight-regular;
        font-size: $text-xs-plus;
        line-height: 15px;
        color: $primaryColor;

        .icon-only.footer-msg-icon {
          cursor: default;
        }

        .footer-msg-icon {
          position: absolute;
          left: 0;

          .info-icon {
            fill: none;

            @media only screen and (max-width: 768px) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .footer-btns {
        padding: 16px 24px 16px 24px;
        display: flex;
        // column-gap: 12px;
      }
    }
  }
}

.app-btn-edit {
  flex-shrink: 0;
}

.close-icon-mobile {
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
  }
}
        // .admin-filter-wrapper {
        //   .filter-applied,.sort-applied {
        //     position: relative;

        //     &::after {
        //       position: absolute;
        //       width: 10px;
        //       height: 10px;
        //       background-color: $lightRed;
        //       ;
        //       border-radius: 50%;
        //       content: "";
        //       top: -3px;
        //       right: -3px;

        //     }
        //   }

        //   .filter-panel-icon {
        //     .filter-icon {
        //       @media (max-width: 767px) {
        //         color: $adminIconColor;
        //       }
        //     }

        //     @media (max-width: 1200px) {
        //       padding: 6px 19px;
        //     }
                      

        //     @media (max-width: 991px) {
        //       padding: 6px 10px;
        //       border: 1px solid $lightDark;

        //     }


        //   }
        //   .sort-icon.sort-applied{           
        //     &:hover{
        //       background-color: transparent;
        //       color: $adminIconColor;
        //     }
        //   }
        // }

                    .filter-icon{
              width: 18px;
            }
