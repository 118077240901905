@use"../../../../../../assets/scss/abstracts/" as *;
.add-skill-data {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid rgba($darkBrown, 0.14);
}
.skill-data-heading{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $bgColor3;
    padding:10px 24px 12px ;
    position: relative;
    z-index: 5;
    align-items: center;
    position:sticky;
    top:0;
    // border-top: 1px solid rgba($darkBrown, 0.14);
    &.edit-mode{
      padding: 10px 24px 6px;
    
    }
  
  .skill-data-heading-th1{
    width: 100%;
    justify-content: space-between;
  }
  .skill-data-heading-th2{
    width: 100%;
    flex-direction: column;
    .skill-heading-edit-mode{
        display: flex;
        align-items: end;
        padding-top:10px ;
        gap:10px;  
        .input-wrapper{
            .input-field{
              &::placeholder{
                font-size: $text-sm;
            }
            }
        } 
    .addskillname-btn{
            height: 40px;
            width:48px;
            border-color:  $adminIconColor;;
            .plus-icon{
                color: $adminIconColor;
                width: 16px;
                height: 16px;
            }
         }
    
    }
    .input-wrapper{
        width:91%;
         margin-bottom: 12px;
        height: 36px;
     }
     
  }
  .skill-data-title{
  color: $darkBrown;
  font-size: $text-sm;
  font-weight: $font-weight-medium;
   text-transform: uppercase;
  }
  .skill-data-subtitle{
    color: $darkBrown;
    font-size:$text-sm;
  font-weight: $font-weight-medium;
    text-transform: uppercase;
  }
  }
.skill-data-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     border-top: 1px solid rgba(0, 0, 0, 0.1);
     padding:16px 0;
     margin: 0 24px 0 24px;
     position: relative;
     &.edit-mode{
             padding: 2px 0 0 0  ;
             gap:4px;
            //  margin-right:12px;
             .skill-data-details{
          padding: 4px 0;
             }
     }
     &:first-child{
         border-top:0;
     }
    
   
    
     .skill-data-details{
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
         .skill-edit-btn{
            .edit-btn{
               height: 36px;
               width: 49px;
               border-color:  $adminIconColor;;
             }
             .edit-icon{
                fill: none;
                 width: 16px;
                 height: 16px;
             }
             }
        
     }
     .skill-data-name{
             color: $black;
             font-size: $text-md;
             font-weight:$font-weight-medium ;
             word-break: break-word;
             width:80%;
            
     }
     .skill-data-count{
        color: $black;
        font-size:$text-md;
       font-weight: $font-weight-medium;
       padding-right: 22px;
   
 
     }
     .skill-data-edit-wrapper{
         background-color: #F5F9FE;;
         gap:8px;
        align-items: flex-start;
         padding-bottom: 5px;
        .skildata-btn{
             top: 15px;
             .close-icon{
              width: 14px;
              height: 14px;
             }

         }
         .input-wrapper{
            //  width:81%;
            flex-grow: 1;
              margin-bottom: 0;
              @media screen  and (max-width: 758px) {
                 width:86%;
             }
          }
          &th-close{
             display: none;
          }
    }
     
}
 