@use "../../../../../assets/scss/abstracts/" as *;

.skills-grid-view {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  padding-top: 8px;

  @media (max-width:991px) {
    padding-top: 0;
    padding-bottom: 2px;
  }

  .skill-layout {
    flex: 1 1 1px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 8px 16px 16px 16px;

    @media (max-width:991px) {
      padding: 0px 16px 0px 16px;
    }

    .skill-layout-header {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $adminPrimaryGray;
    }

    .layout {

      gap: 16px;
      margin-top: 8px;

      @media (max-width:1200px) {
        --responsive-box-count: 4;
      }

      @media (max-width:991px) {
        --responsive-box-count: 3;
      }

      @media (max-width:767px) {
        --responsive-box-count: 2;
      }

      @media (max-width:480px) {
        --responsive-box-count: 1;
      }

      .skill-item-set {
        border-radius: 8px;
        position: relative;

        .skill-item-link {
          position: relative;
          padding: 12px 10px 14px 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          text-decoration: none;
          top: 0;
          left: 0;

          &:focus-visible {
            border-radius: 8px;
          }

          .skill-item-top {
            display: flex;

            .skill-item-name {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              word-break: break-word;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $black;
              margin-bottom: 16px;
              max-width: calc(100% - 67px);
              position: relative;

            }
            .skill-item-names {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              word-break: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #000000;
              margin-bottom: 25px;
            }

            .skill-counts {
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              margin-left: 8px;
              color: $secondaryColor ;
              flex-shrink: 0;
            }
          }

          .skill-item-bottom {
            display: flex;
            justify-content: space-between;

            .skill-num-edit-wrapper {
              display: flex;
              flex-direction: column;

              align-items: flex-start;
              color: $black;
              gap: 2px;

              .skill-count {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;

                .skill-added {
                  font-weight: 400;
                  margin-left: 4px;
                }
              }

              .skill-level {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
              }
            }
          }

        }




        .edit-icon-btn {
          border: 1px solid rgba($darkBrown, 0.14);
          border-radius: 4px;
          padding: 1px;
          position: absolute;
          right: 9px;
          top: 13px;
           z-index: 2;
          transition: background ease 0.2s;

          &:hover {
            background-color: rgba($darkBrown, 0.14);
          }

          @media (max-width: 991px) {
            border: none;
            background-color: rgba($color: $black, $alpha: 0.05);
            border-radius: 16px 0 10px 0;
            position: absolute;
            padding: 3px;
           
          }

          .edit-icon {
            fill: none;
            width: 16px;
            height: 16px;
            color: $darkBrown;
          }
        }
      }
    }
  }

  .purple-shade {
    .skill-item-set {
      background: $adminTilePink;
    }
  }

  .blue-shade {
    .skill-item-set {
      background: $adminTileBlue;
    }
  }

  .gray-shade {
    .skill-item-set {
      background: $adminTileGray;
    }
  }

  .add-skill-footer-btn {
    width: calc(100% - 8px);
    padding: 19px 16px 25px 16px;
    background: $white;
    box-shadow: 0px -1px 11px rgba($black, 0.1);
    z-index: 4;
    position: fixed;
    margin: 0 auto 0 auto;
    bottom: 4px;
    left: 0;
    right: 0;
    display: none;

    &.with-bg {
      .app-btn {
        width: 100%;
        color: $white;
        border: 1px solid $adminBgPrimaryLighter;
        background: $adminBgPrimaryLighter;
        border-radius: 6px;
      }
    }

    @media (max-width: 991px) {
      display: flex;
    }
  }
}
.admin-search-panel {
  .search-btn-wrapper {
    .skill-left-field {
      .primary-skill-wrapper {
        margin: 0 8px;
      }
    }
  }
}