@use "../../../../assets/scss/abstracts/index" as *;

.skills-grid {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1 1 1px;
    overflow-y: auto;
    // gap: 16px;

    @media (max-width:991px) {
        padding: 22px 24px 24px;
        display: none;

        &.show-this {
            display: flex;
            flex-direction: column;
        }
    }
}

.user-skills-wrapper {
    display: grid;
    grid-template-columns: (1fr 1fr);
    // gap: 16px;
    border-radius: $border-radius8;
    user-select: none;

    @media (max-width:768px) {
        grid-template-columns: (1fr);
    }

    .skills-card-box {
        min-width: 50%;
        user-select: none;
    }

    &.main {
        gap: 16px;

        .skill-type {
            margin-bottom: 15px;

            @media (max-width:768px) {
                margin-bottom: 12px;
            }
        }

        .skills-card-box {
            background: $bgColor2;
            flex: 1;
            padding: 16px;
            border-radius: $border-radius8;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &.additional {
        @extend %flex-full-height;
        // flex: 1 1 1px;
        flex-shrink: 0;
        background: $bgColor4;
        padding: 16px;
        margin-top: 16px;

        @media (max-width:991px) {
            background: $bgColor16;
        }

        .skill-type {
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
        }

        .app-btn {
            &.add-btn {
                @media (min-width:767px) {
                    display: none;
                }
            }
        }
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .skill-type {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: $text-md;
        display: flex;

        .tooltip-content {
            margin-top: 4px;
            background-color: transparent;
            font-weight: $font-weight-regular;
            font-size: $text-xs;
            line-height: 12px;
            color: $primaryColor;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }

    }

    .skills-count {
        @extend %flex-all-center;
        background-color: $fancyBg2;
        height: 21px;
        width: 21px;
        border-radius: $border-radius-rounded;
        color: $white;
        margin-left: 6px;
    }

    .additional-skills-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        // gap: 16px;
        grid-gap: 16px;
        align-items: flex-start;

        @media (max-width:768px) {
            grid-template-columns: repeat(1, auto);
        }

        .skills-card-box {
            @media (max-width:768px) {
                display: none;
            }
        }

    }
}

.skill-placeholder {
    border-radius: $border-radius5;
    position: relative;
    height: 90px;
    transition: all ease 0.3s;
    min-width: 50%;

    &.droppable {
        border: 1.5px dashed $borderColor4;
    }

    &.droped {
        border: 1px solid transparent;
        position: relative;
        box-shadow: $cardBoxShadow;

        @media (max-width:991px) {
            z-index: initial;
        }

        &.verify {
            border-color: $borderColor3;
        }

        .update-skill-level {
            z-index: 8;

        }
    }

    &.drop-over {
        border-color: $secondaryColor;
        background-color: $white;

        .user-skill-drop {
            background-color: $white;
        }
    }


}

.user-skill-drop {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius5;
    background: $bgColor12;
    padding: 16px 50px 16px 16px;
    font-size: $text-sm;
    transition: background ease 0.3s;

    @media (max-width:991px) {
        padding: 50px 16px 24px 16px;
        justify-content: center;
    }

    .desktop {
        @media (max-width:768px) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @media (max-width:768px) {
            display: block;
        }
    }

    .add-skill-btn {
        position: absolute;
        margin: auto;
        top: 0;
        right: 16px;
        bottom: 0;
        width: 20px;
        height: 20px;
        line-height: 1.2;
        pointer-events: none;

        @media (max-width:991px) {
            pointer-events: auto;
            top: 22px;
            right: 0;
            left: 0;
            bottom: inherit;
        }

        svg {
            width: 18px;
            height: 18px;
            stroke-width: 1;
        }
    }
}