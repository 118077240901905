@use "../../assets/scss/abstracts/index" as *;

.no-page-found {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  .no-page-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .logo-icon-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
      .logo-icon {
        width: 161px;
        height: 47px;
      }
    }
    .no-page-image {
      margin-bottom: 45px;
      text-align: center;
      .page-img {
        @media only screen and (max-width: 768px) {
          width: 70%;
          display: inline-block;
        }
      }
    }
    .no-page-btn-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .app-btn {
        min-width: 238px;
      }
    }
    .no-page-content-msg {
      font-weight: $font-weight-semi-bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom: 65px;
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}
