@use "../../../../../assets/scss/abstracts/" as *;

.admin-search-panel {
  padding: 13px 16px;
  background-color: $white;
  position: relative;
  &.user-data{
    @media (max-width: 767px) {
      padding-bottom: 8px;
       }
  }
  .search-btn-wrapper {
    display: flex;
    justify-content: space-between;

    &.search-mode {
      .filter-panel-icon {
        @media (max-width: 991px) {
          display: none;
        }
      }

      .skill-left-field {
        .bubble-grid-wrapper {
          @media (max-width: 991px) {
            display: none;
          }
        }

        .search-field {
          .back-btn {
            @media (max-width: 991px) {
              display: block;
            }
          }

          .search-btn {
            @media (max-width: 991px) {
              display: none;
            }
          }
        }
      }
    }

    .skill-left-field {
      display: flex;
      align-items: center;
      position: relative;
      // column-gap: 12px;
      @media (max-width: 991px) {
        padding-right: 12px;
      }
      &.user-data {
        @media (max-width: 991px) {
          padding-right: 0;

        }

        .primary-skill-wrapper {
          @media (max-width: 767px) {
            width: 100%;
            // padding-left: 0;
            // padding-bottom: 0;
          }


        }

        .admin-filter-wrapper {
          .filter-applied,.sort-applied {
            position: relative;

            &::after {
              position: absolute;
              width: 10px;
              height: 10px;
              background-color: $lightRed;
              ;
              border-radius: 50%;
              content: "";
              top: -3px;
              right: -3px;

            }
          }

          .filter-panel-icon {
            .filter-icon {
              @media (max-width: 767px) {
                color: $adminIconColor;
              }
            }

            @media (max-width: 1200px) {
              padding: 6px 19px;
            }
                      

            @media (max-width: 991px) {
              padding: 6px 10px;
              border: 1px solid $lightDark;

            }


          }
          .sort-icon.sort-applied{           
            &:hover{
              background-color: transparent;
              color: $adminIconColor;
            }
            .filter-icon{
              width: 18px;
            }
          }
        }
      }

      @media (max-width: 991px) {
        flex-grow: 1;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        row-gap: 6px;
        
      }

      .search-field {
        position: relative;
        display: flex;
        flex-grow: 1;
        margin-right: 8px;
        @media (max-width: 767px) {
          width: calc(100% - 148px);
        }

        .input-wrapper {
          margin-bottom: 0;
          flex-grow: 1;

          label {
            display: none;
          }

          .input-field {
            padding-left: 32px;
            height: 32px;
            position: relative;
            background: $bgColor13;
            border-radius: 6px;
            border: 1px solid $bgColor13;
            font-size: $text-sm;
            color: $darkBrown;
            width: 308px;
            @media (max-width: 1365px) {

              width: 240px;

            }
            @media (max-width: 991px) {
              height: 40px;
              width: 100%;
            }

            @media (max-width: 991px) {
              padding-left: 40px;
            }
          }
        }

        .search-btn,
        .back-btn {
          position: absolute;
          left: 12px;
          top: 9px;
          color: $darkBrown;

          @media (max-width: 991px) {
            top: 14px;
            left: 16px;
          }

          .back-icon {
            fill: transparent;
          }
        }

        .search-btn {
          pointer-events: none;
        }

        .back-btn {
          display: none;
        }
      }

      .bubble-grid-wrapper {
        background: $adminIconBg;
        border-radius: 6px;
        display: flex;
        border: 1px solid $adminIconBorder;
        background-color: $adminIconBg;
        margin: 0 8px;
        

        .icon-only {
          color: $adminBtnLightBtn;
          width: 32px;
          height: 30px;
          padding: 8.5px 10px;

          &.active {
            color: $adminBgPrimaryLighter;
          }

          @media (max-width: 991px) {
            width: 40px;
            height: 38px;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          svg {
            width: 14px;
            height: 14px;
          }

          &.grid-btn {
            border-left: 1px solid $adminIconBorder;
          }
        }
      }

      .primary-skill-wrapper {
        font-weight: $font-weight-regular;
        font-size: $text-xs-plus;
        line-height: 13px;
        color: $black;
        padding: 8px 16px 8px 12px;
        border-radius: 6px;

        @media (max-width: 991px) {
          // display: none;
          padding: 13px 12px 12px 12px;
        }
        @media (max-width: 767px) {
          // display: none;
          padding: 13px 12px 12px 12px;
        }

        &.purple-shade {
          padding: 6px 16px;
          border-radius: 6px;
          background: $adminTilePink;
          gap: 4px;
          min-height: 32px;
          white-space: nowrap;
          font-weight: $font-weight-medium;
          // border: 1px solid $lightDark;
          display: flex;
          align-items: center;
          line-height: 18px;
          @media (max-width: 1365px) {
            padding: 6px 10px;
          }
          @media (max-width: 991px) {
            padding: 10px 10px;
          }
        }

        &.blue-shade {
          background: $adminSkyBlue;
          gap: 4px;
          display: flex;
          align-items: center;
          line-height: 18px;
        }

        &.gray-shade {
          background: $adminTileGray;
        }
        &.md{
          font-size: 12px;

font-weight: 500;
        }
       

        .skill-count {
          font-weight: $font-weight-semi-bold;
          font-size: $text-md;
          margin-right: 2px;
          @media (max-width: 1365px) {

            font-size: $text-sm;

          }

          &.blue-shade {
            color: var(--blue-b-200, #3B81DF);
            margin-left: 5px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
        }
        &.domain-primary-skill-wrapper {
          margin: 0 8px;
          @media (max-width: 767px) {
            margin: 0;
          }
        }
      }
    }

    .option-dropdown-wrapper {
      position: absolute;
      top:2px;
      display: none;
      right: -8px;
      &.disable{
        @media (max-width: 991px) {
          display: none;
        }
      }

      @media (max-width: 991px) {
        display: block;
      }

      .icon-option {
        transform: none;
        background-color: transparent;
        height: 38px;
        width: 16px;
        padding: 0;
        color: $darkBrown;

        .menu-dot-icon {
          width: 16px;
          height: 19px;
        }
      }

      .option-dropdown {
        right: 0px;
        top: 40px;
      }
    }

    .app-btn {

      &.secondary,
      &.primary {
        @media (max-width: 1200px) {
          padding: 6px 8px;
        }

        &.addskill-btn {
          gap: 0;
        }

        &.edit-mode {
          border-color: $adminBgPrimaryLighter;
          background: $white;
        }
      }
    }
  }

  .filter-panel-icon {
    &.querybuilder{
      margin-left: 8px;
    }
    .filter-icon {
      width: 16px;
      height: 16px;
      color: $adminIconColor;
      

      @media (max-width: 991px) {
        width: 20px;
        height: 20px;
        color: $adminIconColor;
      }
      
    }

    .edit-icon,
    .export-icon {
      fill: none;
      color: $adminIconColor;
      width: 16px;
      height: 16px;
    }

    .btn-text {
      margin-left: 3px;
      font-weight: $font-weight-medium;
      font-size: $text-sm;
      line-height: 1.21;
      color: $adminPrimaryBlack;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  .skill-right-field {
    display: flex;
    align-items: center;
    column-gap: 8px;
    &.user-tab {
      @media (max-width: 767px) {
      flex-direction: row-reverse;
      align-items:  start;
    
      }
      .filter-panel-icon {
        padding: 7px 16px;
        border: 1px solid $lightDark;
        &.app-btn.secondary{
          &:hover{
            background-color: transparent;
          }
        }
        // @media (max-width: 1200px) {
        //   padding: 11px 21px;
        // }
        @media (max-width: 991px) {
          padding: 11px 10px;
        }
      
        @media (max-width: 767px) {
          // margin-top: 33px;
          // padding: 11px 16px;
      
        }
        

        .filter-icon {
          @media (max-width: 1200px) {
            color: $adminIconColor;
          }
        }        
      }

      @media (max-width: 991px) {
        display: inherit;
      }
    }

    @media (max-width: 991px) {
      display: none;
    }

    
    .app-btn.with-bg {
      background: $adminBgPrimaryLighter;
      border-radius: 6px;
      border: 1px solid $adminBgPrimaryLighter;
      color: $white;
      padding: 8.5px 16px 8.5px 18px;
      position: relative;

      @media (max-width: 991px) {
        font-weight: $font-weight-semi-bold;
      }

      &:hover {
        background: darken($adminBgPrimaryLighter, 5%);
        border-color: $adminBgPrimaryLighter;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }

    .addskill-btn {
      .button-text {
        margin-left: 8px;
      }
    }
  }

  .app-btn.link-btn {
    display: flex;
    justify-content: flex-start;

    .app-btn-text {
      width: 40px;
    }

    .edit-icon,
    .export-icon {
      fill: none;
      width: 14px;
      height: 14px;
      color: $adminIconColor;
    }
  }
}

.admin-filter-wrapper {
  position: relative;
  display: inline-flex;
  gap: 8px;
  .sort-icon{
    color: $adminIconColor;
    display: none;
    @media (max-width: 767px) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    .svg-icon{
      width: 16px;
      height: 16px;
    }
  }
}

.sort-panel{
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid $toggleBg;
  width:100%;
  z-index: 3;
  border-radius: 5px;
  background-color: $white;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.2s;
  &.active{
    opacity: 1;
    pointer-events: auto;
    width: calc(100% - 32px);
    top: 90px;
  }
  
  @media (max-width:767px) {
    display: flex;
    flex-direction: column;
    &.active {
      top:90px;
    }
  }
  .sort-panel-head {
    padding: 6px 16px;
    border-radius: 5px 5px 0 0;
  }
  .sort-panel-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: $bgColor22;;
    padding:9px 16px;
    .radio-btn-wrapper {
      border: 1px solid $bgColor15;
      border-radius: 5px;
      background-color: $white;
      padding: 0px;
      .app-radio-btn{
        width: 100%;
        padding: 10px;
      }
    }
  }
  .sort-panel-footer {
    padding:16px 16px;
    border-radius:0 0 5px 5px;
    .radio-btn-wrapper {
      padding: 0;
    }
    
  }
    .sort-panel-footer-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }
    .sort-panel-footer-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      border-top: 1px solid $toggleBg;
      padding-top: 16px;
      .app-btn{
        min-width: 90px;
      }
    }
  .sort-panel-labels{
    font-size: $text-sm;
    line-height: normal;
    color: $black;
  }
    .app-radio-btn{
      font-weight: $font-weight-regular;
    }
}
.mob-view-buttons{
  display: none;
}
.web-view-buttons{
  display: flex;
}
@media (max-width: 767px) {
  .mob-view-buttons{
    display: flex;
  }
  .web-view-buttons{
    display: none;
  }
}
.flex-mob{
  display: flex;
  width: 100%;
}