@use "../../../../../../assets/scss/abstracts/" as *;

.compose-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}

.compose-sender-section {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: baseline;
    &.error {
        .selectBox-Wrapper{
            .react-multi-select__control {
                border-radius: 6px;
                border-color: $lightRed1;
            }
        }
        .error-message{
            visibility: visible;
            margin-bottom: 6px;
        }
       
    }

    .sender-tag {
        color: $black;
        font-size:$text-md;
        font-weight: $font-weight-medium;

        @media (max-width: 768px) {
            color: $bgColor21;
            font-size: $text-sm;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
    }
}

.compose-subject-section {
    display: flex;
    gap: 8px;
    align-items: baseline;
    &.error {
        .input-field {
            border-color: $lightRed1;
        }

    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
    }

    .subject-tag {
        color: $black;
        font-size: $text-md;
        font-weight: $font-weight-medium;
        line-height: 16px;
        @media (max-width: 768px) {
            color: $bgColor21;
            font-size: $text-sm;
        }
    }

    .subject-input-section {
        flex: 1;
        width: 100%;
      
    }

    .input-wrapper {
        margin-bottom: 0;
        label {
            display: none;
        }
        .input-field {
            border-radius: 6px;
        }
    }
}

.compose-message-section {
    min-height: 172px;
   position: relative;
    flex: 1;
    border-radius: 6px;
    background: $white;
    margin-top: 22px;
    border: 1px solid $borderColor5;
    &.error {
        border: 1px solid $lightRed1;
        margin-bottom: 10px;
        .error-message{
            visibility: visible;
            position: absolute;
            bottom: -20px;
        }
    }

    .ql-toolbar.ql-snow {
        border-radius: 6px 6px 0 0;
        border: transparent;
        border-bottom: 1px solid $borderColor5;
        .ql-formats {
            position: relative;
            margin-right: 12px;
            &:not(:nth-last-child(2))::after {
                position: absolute;
                content: "";
                height: 70%;
                width: 1px;
                right: -5px;
                top: 3px;
                background-color: $borderColor16;
            }
        }
        .ql-stroke {
            stroke-width: 1.2;
            stroke:$fancyBg9;
        }
        .ql-link {
            rotate: 90deg;
        }
        .ql-background {
            display: none;
        }
    }

    .ql-container.ql-snow {
        border-radius: 0 0 6px 6px;
        border: transparent;
        height: 73%; 
        .ql-editor{
            &:focus-visible {
                outline: none ;
            }
        
        }
    }
}




.sender-input-section {
    flex: 1;
    width: 100%;
    .selectBox-Wrapper {
   .react-multi-select__control {
            border-radius: 6px;
            border: 1px solid $borderColor5;
            .react-multi-select__value-container {
                &.react-multi-select__value-container--has-value {
                    border-radius: 6px;
                    border: 1px solid $adminBgPrimaryLighter;
                }
            }
        }
     .react-multi-select__menu-list {
         padding: 0;
        }
     .react-multi-select__option {
            padding: 8px;
         &.react-multi-select__option--is-focused {
                background-color: $bgColor23;
            }
            &.react-multi-select__option--is-selected {
                color: $black;
            }
        }

        .image-option-wrapper {
            display: flex;
            gap: 4px;
            align-items: center;
            .user-img {
                width: 16px;
                height: 16px;
                border-radius: 16px;
            }
            .user-image {
                width: 16px !important;
                height: 16px !important;
                border-radius: 16px !important;
            }
            .close-button {
                border: none;
                background-color: transparent;
            }
            .image-label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .addition-option-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .image-holder {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .option-image {
                width: 16px;
                height: 16px;
                border-radius: 16px;
            }

            .close-button {
                border: none;
                background-color: transparent;
            }
        }
       
    }
}
.add-recipient-section{
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    .selection-box {
        label {
            display: none;
        }
        .selectBoxWrapper {
            .react-add-recipient__control {
                border-color: transparent;
                box-shadow: none;
                min-height: 0;
                .react-add-recipient__value-container {
                    padding-right: 20px;
                }
            }
            .react-add-recipient__menu-list {
                padding-top: 0;
                .react-add-recipient__option--is-focused,
                .react-add-recipient__option--is-selected {
                    background-color: $bgColor23;
                }
            }
            .react-add-recipient__placeholder {
                color: $adminBgPrimaryLighter;
                font-size: $text-md;
                font-weight:$font-weight-regular;
            }
            .react-add-recipient__indicator-separator {
                display: none;
            }

            .react-add-recipient__indicator {
                display: none;
            }
        }
        .dropdown-arrow {
            top: 5px;
            .svg-icon {
                color: $adminBgPrimaryLighter;
            }

        }
    }
}

.sender-input-section .countSelect {
        display: flex;
        .selectBoxWrapper {
            label {
                display: none;
            }
            .validationMessage {
                display: none;
            }
            .react-dropdowon-recipient__value-container {
                padding-left: 0;
            }
            .react-dropdowon-recipient__control {
                min-height: auto ;
                border: transparent;
                background-color: $lightWhiteShade;
                &.react-dropdowon-recipient__control--is-focused {
                    box-shadow: none;
                }
            }
    
            .react-dropdowon-recipient__placeholder {
                color: $black;
                font-size: $text-sm;
                font-weight: $font-weight-regular; 
            }
    
            .react-dropdowon-recipient__indicator-separator,
            .react-dropdowon-recipient__indicator {
                display: none;
            }
    
            .react-dropdowon-recipient__menu {
                width: 200px;
                .react-dropdowon-recipient__option {
                    &.react-dropdowon-recipient__option--is-focused {
                        background-color: $bgColor23;
                    }
                }
            }
        }
        .close-button {
            border: none;
            background-color: transparent;
            padding: 0;
    
        }
    
    }

.error-message{
    visibility: hidden;
    display: flex;
    align-items: center;
  gap: 3px;
    color: $lightRed1;
    font-size:$text-xs;
    font-weight:$font-weight-regular;
    svg{
        width: 16px;
        height: 16px;
    }
}

