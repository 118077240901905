@use "../../../assets/scss/abstracts/index" as *;
.edit-custom-skill {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  .input-icon-wrapper {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 12px;
    .input-wrapper {
      width: 100%;
      margin-bottom: 13px;
    }
    .icon-only{
      width: 24px;
      height: 24px;
      position: relative;
      top: -5px;
      border-radius: 3px;
      &:hover{
          background-color: $bgColor15;
      }
    }
    .delete-icon {
      width: 16px;
      height: 16px;
      fill: none;
      color: $darkBrown;
    }
  }
}
