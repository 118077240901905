@use "../../../assets/scss/abstracts/index" as *;

.app-c-modal {
  @extend %screen-overlay;
  @extend %flex-all-center;
  flex-direction: column;
  &.two-column-modal {
    .modal-wrapper {
      max-width: 871px;
      overflow: hidden;
      @media screen and (max-width: 767px) {
        overflow-y: auto;
      }
    }
    .modal-header {
      .icon-only {
        z-index: 4;
      }
    }
    .modal-body {
      overflow-y: visible;
      @media screen and (max-width: 767px) {
        overflow-y: auto;
      }
    }
    .app-modal-content {
      display: flex;
    }
  }
  &.h-full {
    .modal-wrapper {
      height: 100%;
      .modal-body {
        @extend %flex-full-height-auto;
        @media only screen and (max-width: 768px) {
          border-radius: 16px 16px 0 0;
        }
        .app-modal-content {
          @extend %flex-full-height-auto;
        }
        @media only screen and (max-width: 768px) {
          padding-bottom: 0;
        }
      }
    }
  }
  &.mobile-card-header {
    .placement-full {
      @media only screen and (max-width: 768px) {
        background: $adminBgPrimary;
      }
      .modal-header {
        @media only screen and (max-width: 768px) {
          border-radius: 16px;
          margin: 38px 8px 4px 8px;
          background: $adminTileBlue;
          position: relative;
          padding: 12px 16px;
          h4 {
            align-items: flex-start;
            line-height: 1.2;
            margin-bottom: 6px;
            .emailIcon {
              top: 3px;
              position: relative;
            }
          }
          .back-arrow {
            position: absolute;
            top: -28px;
            left: 10px;
            color: $white;
          }
          .close-icon {
            display: none;
          }
          .btn-view-mail {
            position: absolute;
            // bottom: 14px;
            right: 14px;
            padding: 0;
            background: none;
            border: none;
            font-weight: $font-weight-regular;
            line-height: 1.2;
          }
        }
      }
      .modal-body {
        @media only screen and (max-width: 768px) {
          padding: 16px;
        }
      }
    }
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: calc(100% - 32px);
  border-radius: $border-radius6;
  box-shadow: $modalBoxShadow1;
  max-height: calc(100vh - 40px);

  &.sm {
    max-width: 480px;
  }

  &.md {
    max-width: 640px;
  }
  &.xl {
    max-width: 1200px;
  }

  &.placement-bottom {
    @media only screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      width: 100%;
      border-radius: $border-radius6 $border-radius6 0 0;

      .modal-header {
        justify-content: space-between;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .icon-only.back-arrow {
        display: none;
      }

      .modal-body {
        @media only screen and (max-width: 768px) {
          padding-top: 16px;
          padding-bottom: 24px;
        }
      }
    }

    .modal-footer {
      @media only screen and (max-width: 768px) {
        border-radius: 0;
      }
    }
  }

  &.placement-full {
    @media only screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      border-radius: 0;
      background: $bgColor9;
    }

    .modal-footer {
      @media only screen and (max-width: 768px) {
        border-radius: 0;

        &.hide-footer {
          display: none;
        }

        .footer-content {
          display: none;
        }
      }
    }
  }

  .modal-header {
    padding: 24px 24px 0 24px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 14px;
    // .title-content{
    //   max-width: 88%;
    //   @media only screen and (max-width: 1200px) {
    //     max-width: 80%;
    //   }
    // }
    .header-title{
      align-items: flex-start;
      margin-bottom: 6px;
    }
    &.blue-shade {
      @media only screen and (max-width: 768px) {
        background-color: $adminBgPrimary;
        border-radius: 0;
        margin-bottom: 0;
        .header-title {
          color: $white;
        }
        .back-arrow {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-bottom: 8px;
      background: $white;
      padding: 19px 24px 19px 24px;
      border-radius: $border-radius7 $border-radius7 0 0;
    }

    h4 {
      display: flex;
      align-items: center;
      color: $headingColor;
      font-size: $text-xl;
      line-height: 1.2;
      font-weight: $font-weight-medium;
      word-break: break-word;
      gap: 8px;
      .emailIcon {
        color: $bgColor21;
        width: 18px;
        height: 18px;
        // padding-top: 6px;
      }
    }

    .icon-only {
      width: 18px;
      height: 18px;
      background: none;

      .close-icon {
        @media only screen and (max-width: 768px) {
          width: 12px;
          height: 12px;
          color: $lightGrey;
        }
      }

      &:hover {
        color: $primaryColor;
      }
    }

    .back-arrow {
      display: none;

      @media only screen and (max-width: 768px) {
        display: block;
      }

      .back-arrow-icon {
        fill: none;
      }
    }

    .close-icon {
      &.hide-all {
        display: none;
      }
      &.hide {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .btn-icon-wrapper {
      display: none;
      color: $borderColor4;
      font-weight: $font-weight-medium;
      font-size: $text-md;
      width: 50px;

      .edit-icon {
        width: 16px;
        height: 16px;
        fill: none;
      }

      .btn-text {
        margin-left: 5px;
      }

      &.show {
        @media only screen and (max-width: 768px) {
          display: inline-flex;
          flex-grow: 1;
          justify-content: flex-end;
        }
      }
    }
  }
  .modal-header-bottom {
    padding: 0px 0px;
    .modal-header-bottom-text {
      font-size: $text-sm;
    }
  }


  .modal-body {
    padding: 12px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    &.compose-data {
      padding-bottom: 10px;
      @media only screen and (max-width: 768px) {
        padding: 24px 24px 36px 24px;
      }
    }

    @media only screen and (max-width: 768px) {
      padding-bottom: 0;
      background: $white;
      padding: 24px 24px 36px 24px;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $bgColor4;
    border-radius: 0 0 $border-radius6 $border-radius6;
    gap: 12px;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      background-color: $white;
    }

    &.space-between {
      justify-content: space-between;
      gap: 0;
    }

    .footer-msg {
      position: relative;
      padding-left: 23px;
      font-weight: $font-weight-regular;
      font-size: $text-xs-plus;
      line-height: 15px;
      color: $primaryColor;

      .icon-only.footer-msg-icon {
        cursor: default;
      }

      .footer-msg-icon {
        position: absolute;
        left: 0;

        .info-icon {
          fill: none;

          @media only screen and (max-width: 768px) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .footer-content {
      padding: 18px 0px 26px 24px;

      @media only screen and (max-width: 768px) {
        padding: 16px 24px 24px 24px;
        width: 100%;
      }
    }

    .footer-btns {
      padding: 16px 24px 20px 24px;
      display: flex;
      gap: 12px;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .app-btn.min-width {
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}