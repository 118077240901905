@use "../../../../assets/scss/abstracts/palette" as *;
@use "../../../../assets/scss/abstracts" as *;

.admin-wrapper {
  .side-bar {
    max-width: 320px;
    position: fixed;
    top: 0%;
    width: 100%;
    background-color: $white;
    right: -100%;
    height: 100%;
    z-index: 100;
    transition-property: right;
    transition-duration: 0.5s;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 450px) {
      max-width: 100%;
    }

    &.active {
      right: 0px;
      transition-property: right;
      transition-duration: 0.5s;
      display: flex;
      flex-direction: column;

      .sidebar-header {
        @media only screen and (max-width: 750px) {
          background-color: $adminBgPrimary;
          color: $white;
          
        }

        button,
        .sidebar-title {
          @media only screen and (max-width: 750px) {

            color: $white;
            ;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        background: $bgColor9;
      }

      ~.sidebar-backdrop {
        background: $backDrop1;
        pointer-events: all;
        opacity: 1;
        right: 0;
      }
    }

    .sidebar-header {
      background: white;
      padding: 24px 24px 16px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba($black, 0.1);

      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        padding: 19px 16px 19px 24px;
      }

      .sidebar-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 144%;
        color: $black;

        @media only screen and (max-width: 768px) {
          margin-left: 14px;
        }
      }

      .close-icon {
        display: block;
        .svg-icon{
          width: 16px;
          height: 16px;

        }
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      .back-arrow {
        display: none;

        .back-arrow-icon {
          fill: none;
        }

        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }

    .details-content {
      padding: 0 0 24px 0;
      overflow: auto;
      height: 100%;

      @media only screen and (max-width: 768px) {
        padding: 0 0 20px 0; 
        background: $white;
        max-height: 100%;
        margin-top: 8px;
      }
    }

    &.lg {
      max-width: 458px;
    }

    &.xl {
      max-width: 638px;
      right: -638px;
    }

    .side-page-footer {
      width: 100%;
      justify-content: center;
      padding: 15px 24px 15px 24px;
      column-gap: 12px;
      display: flex;
      align-items: center;
   
      border-top: 1px solid $borderColor13; ;
      
      @media only screen and (max-width: 768px) {
        padding: 24px;
        background: $white;
        box-shadow: 0px -1px 11px rgba($black, 0.1);
        justify-content: center;
      }

      .app-btn {
        min-width: 115px;
        padding: 10px 16px;
        @media only screen and (max-width: 768px) {
        min-width: 156px;
       
        }
        &.secondary.required{
          background-color: $adminfooter;
          

        }
        &.primary.required{
          background-color: $bgColor23;
          border: transparent;

        }
      }
    }
  }

  .sidebar-backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    pointer-events: none;
  }

}