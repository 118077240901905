@use "../../../src/assets/scss/abstracts/index" as *;
.skill-stack{
    @extend %flex-full-height;
    min-height: 0;
    flex: 1 1 1px;
    overflow-y: auto;
    padding: 0 32px 24px;
    @media (max-width:991px) {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        max-height: 100%;
        background-color: $bgColor9;
        width: 100%;
        height: 100%;
    }
    .no-result-found {
        margin-top: 68px;
        display: block;
    }
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
  overflow-y: auto;
  @media (max-width: 991px) {
   margin-top: 8px;
   flex-direction: column;
   gap:10px; 
   & > div {
    & > div[style*="padding-top"] {
        &:not(:first-child) {
            padding-top: 10px !important; /*fix for masonry vertical padding in mobile view*/
        }
    }
   }
  }
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 10px;
}
.skill-set-card{
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    padding: 16px 12px 12px;
    gap: 8px;
    border-radius: $border-radius8;
    align-self: flex-start;
    &.add-by-you{
        border: 1px solid $borderColor3;
        @media (max-width:991px) {
            border: none;
        }
        .skill-item{
            background-color: $bgColor7;
            &.added{
                border-color: $borderColor3;
                background-color: $white;
            }
        }
    }
    @media (max-width:991px) {
        border-radius: $border-radius0;
        padding:24px;
        gap: 12px;
    }
    h5{
        flex: 1 1 100%;
        font-weight: $font-weight-medium;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-only{
            width: 20px;
            height: 20px;
            .edit-icon{
                width: 16px;
                height: 16px;
                fill:none;
                color: $darkBrown;
            }
        }

    }    
}
.skill-item{
        display: inline-flex;
        align-items: center;
        background-color: $bgColor5;
        border: 1px solid $bgColor5;
        padding: 5px 8px;
        border-radius: $border-radius6;
        position: relative;  
        cursor: pointer;
        // cursor: move; /* fallback if grab cursor is unsupported */
        // cursor: grab;
        // cursor: -moz-grab;
        // cursor: -webkit-grab;
        @media (max-width:991px) {
            padding: 13.5px 18px;
        }
        &.added{
            border: 1px solid $bgColor6;
            background-color: $white;
            box-shadow: 0px 3px 14px 0px rgba(161, 116, 204, 0.22);
        }
        &.dragging{
            background-color: $borderColor2;            
            position:fixed;
            pointer-events: none;
            transform: translate(-5000px);
            &.add-by-you-btn{
                background-color: $bgColor7;
                .skill-label{
                    color: $black;
                }
            }
            .skill-label{
            color: $white;
            }
        }
        &[draggable = "false"]{
            background-color: $bgColor15;
            color: $tertiaryColor;
            box-shadow: inset 0 0 3px 0px $colorDim;
            .skill-label{
                color: $colorDim;
            }
        }
        &.dropped{
            background-color: $bgColor15;
            color: $tertiaryColor;
            box-shadow: inset 0 0 3px 0px $colorDim;
            .skill-label{
                color: $colorDim;
            }
        }
        &.selected{
            border-color: $borderColor2;
            background-color: $secondaryColor;
            .skill-label,.icon-drag{
                color: $white;
            }
        } 
        .skill-label{
            display: block;
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: $text-sm;
            line-height: 1.2;
            color: $black;
            font-weight: $font-weight-medium;
        }   
        .icon-alert{
            color:$white;
            margin-left: 10px;
            fill: $fancyBg1;
        }
        .icon-drag{
            width: 5px;
            height: 9px;
            margin-left: 10px;
            color: rgb($black,0.15);
            @media (max-width:991px) {
                display: none;
            }
        }  
        &:focus{
            
        }
    }
    .no-result-found{
        color: $primaryColor;
        font-size: $text-lg;
        text-align: center;
    }