@use "../../../assets/scss/abstracts/index" as *;
.toggle-switch{
    cursor: pointer;
    .toggle-ui {
      background: $toggleBg;
        display: inline-block;
        width: 40px;
        height: 20px;
        border-radius: 11px;
        position: relative;
        &:hover{
            // box-shadow: 0px 0px 0px 4px rgb(32 40 235 / 8%);
        }
    }
    .toggle-ui .toogle-handler {
        display: inline-block;
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $white;
        left: 3px;
        top: 1.7px;
        transition-property: left;
        transition-duration: 0.3s;
    }
    .toggle-input-holder {
        &:checked {
            ~ .toggle-ui {
              background: $black;
                opacity: 1;
                .toogle-handler {
                    left: calc(100% - 19px);
                    .toogle-lock-state {
                        display: block;
                    }
                    .toogle-unlock-state {
                        display: none;
                    }
                }
            }
        }
        ~ .toggle-ui .toogle-handler {
            .toogle-lock-state {
                display: none;
            }
            .toogle-unlock-state {
                display: block;
            }
        }
    }
  
    input.toggle-input-holder {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        border: 0;
        clip: rect(0 0 0 0);
    }
  }