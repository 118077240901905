@use "../../../../../../assets/scss/abstracts/index" as *;
.recipent-modal-wrapper {
  @extend %flex-full-height-auto;
  .modal-sub-heading {
    font-size: 1.6rem;
    font-weight: $font-weight-semi-bold;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  .email-content {
    display: block;

    @media only screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      &::before {
        position: fixed;
        background: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }
    .email-content-holder {
      padding: 0px 0px 20px 26px;
      background: $white;

      @media only screen and (max-width: 768px) {
        border-radius: 16px 16px 0 0;
        padding: 4px;
        max-height: 60vh;
        z-index: 4;
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
      }
    }
    .email-content-header {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
        padding: 12px 16px 12px 44px;
        background-color: $adminTileBlue;
        border-radius: 16px 16px 0 0;
      }
      .email-content-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .go-details {
          width: 16px;
          height: 16px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $lightDark;

          .right-arrow-icon {
            height: 10px;
            stroke-width: 1;
          }
        }

        .mail-subject {
          flex-grow: 1;
          margin-bottom: 2px;
          position: relative;

          .title-icon {
            position: absolute;
            display: block;
            width: 22px;
            left: -26px;
            top: 2px;
            color: $bgColor21;

            .email-icon {
              width: 20px;
              height: 16px;
            }
          }

          .mail-subject-text {
            font-weight: $font-weight-bold;
            font-size: $text-md;
            line-height: 1.2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 30px);
            @media only screen and (max-width: 768px) {
              white-space: normal;
            }
          }
        }
      }
    }
    .email-content-block {
      @media only screen and (max-width: 768px) {
        padding: 16px 20px 40px 20px;
      }
      .email-content-title {
        margin-bottom: 10px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .email-content-text {
        line-height: 1.5;
        font-size: $text-sm;
        max-height: 111px;
        overflow: auto;
      }
    }

    .email-content-label {
      color: $lightDark;
      font-size: $text-sm;
      line-height: 1.2;
      margin-bottom: 6px;
      display: block;
    }
    .email-content-title {
      font-size: $text-md;
      line-height: 1.2;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 6px;
    }
    .email-content-text {
      font-size: $text-md;
      line-height: 1.5;
    }
  }
  .pagination-block {
    display: flex;
    justify-content: space-between;
    padding: 0px 12px 0px 12px;
    border-top: 1px solid $borderColor14;
    @media only screen and (max-width: 768px) {
      justify-content: center;
      border-top: none;
    }
    .item-view-section {
      padding: 11px 0;
      display: flex;
      gap: 8px;
      align-items: center;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
.recipent-modal-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  border-top: 1px solid $borderColor14;
  position: relative;
  margin: 0 -24px;
  padding: 0;
  @media only screen and (max-width: 768px) {
    margin: 0 -16px;
  }
}

.recipent-grid-header {
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid $borderColor14;
  padding: 8px 12px 8px 12px;
  padding-left: 30px;
  flex-shrink: 0;
  @media only screen and (max-width: 768px) {
    border-bottom: none;
  }
  .search-field {
    position: relative;
    @media only screen and (max-width: 768px) {
      flex-grow: 1;
    }

    .search-btn {
      position: absolute;
      width: 12px;
      height: 12px;
      left: 14px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .input-field {
      color: $darkBrown;
      line-height: 1.2;
      padding: 8px 16px 8px 36px;
      background: $bgColor13;
      border: none;
      height: auto;
      @media only screen and (max-width: 991px) {
        padding: 12px 16px 12px 36px;
      }
    }
  }
  .input-wrapper {
    position: relative;
    margin: 0;
  }
  .btn-select-all {
    margin-left: auto;
    flex-shrink: 0;
  }
}
.recipent-modal-grid {
  @extend %flex-full-height-auto;
  border-left: 1px solid $borderColor14;
  width: 100%;

  .user-details-Wrappper {
    display: flex;
    flex-direction: column;
    flex: 1 1 1px;
    overflow: auto;
  }
}
.mail-log-details {
  display: flex;
  flex-direction: column;
  padding: 18px;
  @media only screen and (max-width: 768px) {
    padding: 0 18px;
    margin-top: 20px;
  }

  .mail-card-box {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px 0 12px 26px;
    font-size: $text-sm;
    line-height: 1.2;
    width: 100%;
    &:first-child {
      border-top: none;
    }
    &.detailed {
      padding-left: 0;
      .mail-card-bottom {
        margin-top: 8px;
      }
    }
    &.flex {
      display: flex;
    }
    .undelivered-card-box {
      flex-grow: 1;
    }
    .label-text {
      color: $lightDark;
      font-size: $text-sm;
      line-height: 1.2;
      font-weight: $font-weight-regular;
      margin: 8px 0 2px 0;
    }
  }
  .mail-card-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .go-details {
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $lightDark;
      .right-arrow-icon {
        height: 10px;
        stroke-width: 1;
      }
    }
    .mail-subject {
      flex-grow: 1;
      margin-bottom: 2px;
      position: relative;
      .title-icon {
        position: absolute;
        display: block;
        width: 22px;
        left: -26px;
        top: 2px;
        color: $bgColor21;
        .email-icon {
          width: 20px;
          height: 16px;
        }
      }
      .mail-subject-text {
        font-weight: $font-weight-bold;
        font-size: $text-md;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
        @media only screen and (max-width: 768px) {
          white-space: normal;
        }
      }
    }
  }
  .value {
    font-weight: $font-weight-medium;
    margin-left: 3px;
  }
  .mail-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mail-date {
      color: $lightDark;
      font-size: $text-sm;
      line-height: 1.2;
    }
  }
  .delivery-status {
    margin-bottom: 8px;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        position: relative;
        padding: 0px 10px;
        font-size: $text-sm;
        line-height: 1.2;
        &:first-child {
          padding-left: 0;
          &::before {
            display: none;
          }
        }

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 4px;
          display: block;
          border-radius: 50%;
          background-color: $admingrayDot;
          width: 4px;
          height: 4px;
        }
      }
    }
  }
}
.status-update {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 4px 8px 4px 8px;
  border-radius: 16px;
  gap: 5px;

  &.success {
    border: 1px solid $statusGreen300;
    background-color: $statusGreen100;
    color: $statusGreen800;
  }

  &.pending {
    border: 1px solid $statusOrange300;
    background-color: $statusOrange100;
    color: $statusOrange800;
  }
  &.in-progress{
    border: 1px solid $statusBlue300;
    background-color: $statusBlue100;
    color: $statusBlue800;
  }
  &.failed {
    border: 1px solid $statusPink300;
    background-color: $statusPink100;
    color: $statusPink800;
  }

  .status-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
  }

  .status-icon {
    .svg-icon {
      width: 14px;
      height: 14px;
    }
  }
}
.email-details-desktop {
  @extend %flex-full-height-auto;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.email-details-mobile {
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.normal-text table tbody tr td:nth-child(4) {
  text-transform: none;
}
.undelivered-email-id table tbody tr td:nth-child(5) {
  text-transform: none;
}
.undelivered-email-id table tbody tr td:nth-child(1),
.undelivered-email-id table tbody tr td:nth-child(2) {
  padding-left: 30px;
}
.normal-text  td:nth-child(1){
  padding-left: 30px;
}
.total-recipients,.sent-recipients,.failed-recipients {
  text-align: center;
}
.select-all-right{
  display: none !important;
  @media only screen and (max-width: 768px) {
    display: flex !important;
    justify-content: flex-end;
    width: 100%;
    padding-right: 12px;
  }
}
.btn-select-all[disabled]{
  color: rgb(94, 94, 94);
}
.close-email-btn{
  background-color: transparent;
  border: none;
}
.recipients-spacing{
  width: 200px;
}
// email-content-text
.email-content-text{
  strong{
    font-weight: bold;
  }
  ol{
    list-style: decimal;
  }
  ul{
    list-style: disc;
  }
  em{
    font-style: italic;
  }
}
.select-all-btn {
  display: none !important;
  @media only screen and (max-width: 768px) {
    display: inline-flex !important;
    justify-content: flex-end;
    width: 100%;
    padding-right: 12px;
    position: fixed;
    height: 20px;
    margin-bottom: 20px;
    background-color: white !important;
    z-index: 20;
  }
}

.view-mail-btn{
  .title-content{
    max-width: 88%;
    @media only screen and (max-width: 1200px) {
      max-width: 80%;
    }
  }
      .modal-header-bottom{
      padding-left: 28px;
    }
}