@import "../../assets/scss/abstracts/";

.ql-editor strong{
    font-weight:bold;
}

.ql-editor em {
    font-style: italic;
  }

.ql-tooltip.ql-editing {
    left: auto !important;
    right: 0 !important;
    transform: translateX(-50%);
  }
   
.ql-tooltip .ql-action::before {
    content: none; 
  }
   