@use "../abstracts/index" as *;

table {
  border-collapse: collapse;
  width: 100%;
  font-size: $text-sm;

  td,
  th {
    text-align: left;
    margin: 0;

    // .sortWrapper {
    //   display: flex;
    //   gap: 8px;
    //   align-items: center;
    //   white-space: nowrap;
    // }
    .checkbox-wrapper {
      input[type="checkbox"] {
        margin-right: 0;
      }
    }
  }

  tbody {
    background: $white;

    tr {
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      td {
        padding: 10px 16px;
        font-size: $text-sm;
        font-weight: $font-weight-medium;
        vertical-align: middle;
        word-break: break-word;
        line-height: 14px;
        color: $bgColor20;
        text-transform: capitalize;
        &.has-trigger {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
