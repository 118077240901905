@use "../../assets/scss/abstracts/index" as *;

.app-login {
  background-image: url("../../assets/image/loginbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $adminLoginBg;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  row-gap: 62px;

  @media (max-width: 768px) {
    background-image: none;
    padding: 10px;
  }
}

.app-icon {
  .logo-icon {
    width: 161px;
    height: 43px;
  }
}

.app-login-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 490px;
  height: 277px;
  background: $white;
  box-shadow: 14px 14px 0px 0px $adminBoxShadow, -14px 14px 0px 0px $adminBoxShadow;
  border-radius: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    min-width: auto;
    min-height: 277px;
    border-radius: 20px;
    width: calc(100% - 36px);
    max-width: 400px;
  }

  .app-btn {
    position: relative;
    padding: 12px 50px 12px 12px;
    background: $adminBgPrimaryLighter;
    border-radius: 6px;
    font-weight: 600;
    border: 1px solid $adminBgPrimaryLighter;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $white;
    cursor: pointer;
    height: 48px;

    .microsoft-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex: 1;
      // gap: 50px;
      align-items: center;

      .microsoft-logo-icon {
        width: 24px;
        height: 24px;
        margin-right: 50px;
      }
    }

    &:hover {
      background: $adminBgPrimaryLight;
      border-color: $adminBgPrimaryLight;
      color: $white;
    }

    @media (max-width: 768px) {
      padding-right: 20px;
      padding-left: 45px;
    }
  }

  .login-heading {
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    .main-heading {
      font-weight: $font-weight-bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;

      @media (max-width: 768px) {
        font-size: $text-xl-plus;
      }
    }

    .sub-heading {
      font-weight: $font-weight-regular;
      font-size: $text-lg;
      line-height: 24px;
      color: $primaryColor;
      margin-top: 8px;

      @media (max-width: 768px) {
        font-size: $text-sm-plus;
      }
    }
  }
}