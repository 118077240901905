@use "../../assets/scss/abstracts/index" as *;
.layout-wrapper{
  display: flex;
  height: 100%;
   @media (max-width:991px) {
    flex-direction: column;
  }
  .left-panel-body{
    @extend %flex-full-height;
    min-height: 0;
    flex: 1 1 1px;
    overflow-y: auto;
    padding: 24px 24px 8px;
    gap: 24px;   
    @media (max-width:991px) {
      padding: 24px 0 0;
      gap: 0;
      position: relative;
      &.toggle{
        opacity: 0;
        pointer-events: none;
        position: absolute;
      } 
    }
    .app-tab-wrapper{
      display: none;
      @media (max-width:991px) {
        display: block;
      }
    }
  }
  .home-left-panel{
    @extend %flex-full-height;
    width: 40%;
    height: 100%;
    @media (max-width:1280px) {
      width: 50%;
    }
    &.faq-left-panel{
      @media (max-width:991px){
        height: auto;
      }
      .left-panel-body{
        @media (max-width:991px){
        flex:none;
        padding: 0px 20px 20px 20px;
        }
      }
    }
    &.small-screen{
      @media (max-width:991px) {
          // height: auto;
          position: absolute;
      }
    }
    @media (max-width:991px) {
        width: 100%;
    }
    .mobile-screen-welcome {
      display: none;
      
      @media (max-width:991px) {
        display: block;
        padding: 8px 24px 0 24px;
      }
            .icon-only{
              &.filter-panel-icon{
                display: none
              }
            }
    }
  }
  .home-right-panel{
    @extend %flex-full-height;
    min-height: 0;
    flex: 1 1 1px;
    width: 60%;
    background-color: $bgColor9;
    &.faq-section{
      padding: 42px 42px 42px 60px;
      overflow: scroll;
      @media (max-width:991px){
        height: 100%;
      }
    }
    @media (max-width:1280px) {
      width: 50%;
    }
    @media (max-width:991px) {
      width: 100%;
      position: relative;
      &.toggle{
        opacity: 0;
        pointer-events: none;
        position: absolute;
      } 
    }
  }
}
.home-mobile {
  height: 100%;
  .accordion-section {
    display: none;
  }
  &.home-mobile-none {
    display: none;
  }
}