@use "../../../src/assets/scss/abstracts/index" as *;
.update-skill-level{
    @extend %flex-full-height;
    justify-content: space-between;
    background-color: $white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius5;
    padding: 13px 11px;
    @media screen and (min-width: 992px) and (max-width: 1024px) {
        padding: 13px 10px;
    }
    .option-dropdown-wrapper{
        top: 8px;
        right: 3px;
        @media (max-width:768px) {
            top: 8px;
            right: 12px;
        }
        .icon-option{
            width: 18px;
            height: 18px;
            padding: 0;
            border-radius: 0;
            color: $borderColor5;
            svg{
                width: 3px;
                height: 13px;
                @media (max-width:768px) {
                    width: 4px;
                    height: 16px;
                }
            }
        }
        .option-dropdown{
            right: -5px;
            z-index: 2;
        }
    }
    
    .skill-title{
        font-size: $text-lg;
        font-weight: $font-weight-semi-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 95%;
        padding-bottom: 2px; /* fix for letter cutoff (y,p,g,q letters)*/
    }
    .skill-sub-title{
        font-size: $text-xs;
        color: $primaryColor;
        margin-bottom: 6px;
    }
}
.updated-score{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    .app-tag{
        position: absolute;
        bottom: -4px;
        right: 0px;
        .icon-alert{
            color:$fancyBg1;
            fill: $white;

        }
    }
}