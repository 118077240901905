@use "../../../../assets/scss/abstracts/" as *;
.admin-certifications-wrapper{
  display: flex;
  flex-direction: column;
  background-color: $bgColor1;
  flex:1 1 1px;
  overflow-y: auto;
  background-color: $white;
  border-top: 1px solid $bgColor13;
  border-radius: 0 0 $border-radius4 $border-radius4; 
  .admin-c-modal{
    .modal-header.header-wrapper {
      background: $adminTileBlue;
    }
  }
  @media (max-width:991px){
      padding-bottom: 110px;
      border-top: none;
    } 
}